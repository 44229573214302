import React, { useEffect, useRef, useState } from "react";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { device } from "../../constants/device-sizes";
import {
  removeMessage,
  removeMessagesFromUserId,
} from "../../store/messages-actions";
import {
  MESSAGE_STATUS_CACHED,
  moveMessage,
} from "../../store/messages-actions";
import $ from "jquery";
import bez from "bez/src/jquery.bez";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import useOnboardingTooltip from "../../hooks/useOnboardingTooltip";
import { addToBlockList } from "../../utils/localstorage";
const options = ["one", "two", "three"];
const defaultOption = options[0];

bez($);

const Text = styled.p`
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else if (props.status === MESSAGE_STATUS_CACHED) {
      return "var(--clr-cached)";
    } else {
      return "var(--clr-sec)";
    }
  }};
  font-weight: ${(props) => (props.bold ? "600" : "normal")};
  text-align: center;
  margin: 0;
  line-height: ${(props) => 1.2 * props.fontSize + "px"};
`;

const DateText = styled.p`
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else if (props.status === MESSAGE_STATUS_CACHED) {
      return "var(--clr-cached)";
    } else {
      return "var(--clr-sec)";
    }
  }};
  text-align: center;
  font-size: var(--fs-body-small);
  margin: 0;
  padding: 0;
`;

const Container = styled.div`
  position: absolute;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  max-width: 45%;
  @media ${device.tablet} {
    max-width: 25%;
  }
  opacity: 0;
  will-change: transform, opacity;
  margin: 0;
  padding: 0.5rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const BlockButton = styled.button`
  background-color: var(--clr-tert);
  color: var(--clr-sec);
  border: none;
  margin: 0.5rem 0 0 0;
  padding: 0.5rem 1rem;
`;

const Message = (props) => {
  const dispatch = useDispatch();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [messageMoved, setMessageMoved] = useState(false);
  const [blockButtonVisible, setBlockButtonVisible] = useState(false);

  const messageRef = useRef();
  const tooltipDisabled = useOnboardingTooltip(
    messageRef,
    props.message.onboardingIndex
  );

  function onboardingMessage() {
    let index = props.message.onboardingIndex;
    if (index === 0) {
      return "All messages are from real people";
    } else if (index === 2) {
      return "All messages fade away automatically after a few seconds";
    } else if (index === 3) {
      return "Each person's messages have their own unique color";
    } else {
      return "";
    }
  }

  function onBlockClicked() {
    let blockedUserId = props.message.userId;
    console.log("Blocking user", blockedUserId);

    // Add their userId to the block list
    addToBlockList(blockedUserId);

    // Remove all of their messages
    dispatch(removeMessagesFromUserId(blockedUserId));

    // Make block API request
    props.sendBlockedUserToServer(props.message);
  }

  const { width, height } = size;
  const {
    id,
    rect,
    fontSize,
    fadeOutDuration,
    messageText,
    status,
    color,
    bold,
    createdAt,
  } = props.message;

  useLayoutEffect(() => {
    if (messageRef) {
      setSize({
        width: messageRef.current.clientWidth,
        height: messageRef.current.clientHeight,
      });
    }
  }, [messageRef]);

  useEffect(() => {
    if (height === 0 || width === 0 || messageMoved) {
      return;
    }
    setMessageMoved(true);
    dispatch(moveMessage(id, width, height));
  }, [height, width, dispatch, id, messageMoved]);

  useEffect(() => {
    if (!fadeOutDuration) {
      return;
    }
    $(messageRef.current)
      .fadeTo(1000, 1)
      .fadeTo(fadeOutDuration, 0, $.bez([1, 0.75, 0.9, 0.8]), () => {
        dispatch(removeMessage(id));
      });
  }, [fadeOutDuration, dispatch, id]);

  function focusMessage() {
    $(messageRef.current).stop().fadeTo(750, 1);
  }

  function unFocusMessage() {
    $(messageRef.current)
      .stop()
      .fadeTo(
        messageRef.current.style.opacity * (fadeOutDuration / 2),
        0,
        $.bez([1, 0.75, 0.9, 0.8]),
        () => {
          dispatch(removeMessage(id));
        }
      );
  }

  // console.log("Message with tooltipdisabled", props.message);

  function onClickContainer() {
    if (props.message.userId !== "self") {
      setBlockButtonVisible(!blockButtonVisible);
    }
  }

  return (
    <Container
      data-tip={onboardingMessage()}
      data-tip-disable={props.message.tooltipDisabled}
      data-place="top"
      ref={messageRef}
      style={{
        top: rect.top,
        left: rect.left,
        fontSize: fontSize,
      }}
      onMouseOver={focusMessage}
      onMouseOut={unFocusMessage}
      onClick={onClickContainer}
    >
      <Text fontSize={fontSize} status={status} color={color} bold={bold}>
        {messageText}
      </Text>
      {status === MESSAGE_STATUS_CACHED && (
        <DateText>{moment(createdAt).fromNow()}</DateText>
      )}
      {blockButtonVisible && (
        <BlockButton onClick={onBlockClicked}>Block this user</BlockButton>
      )}
    </Container>
  );
};

function messagePropsAreEqual(prevMessage, nextMessage) {
  return prevMessage.message === nextMessage.message;
}

export default React.memo(Message, messagePropsAreEqual);
