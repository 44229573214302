const SENT_MESSAGES_KEY = "sent_messages";
const DARK_MODE_KEY = "dark_mode";
const ONBOARDING_SHOWN_KEY = "onboarding_shown";
const ONBOARDING_INDEX = "onboarding_index";
const BLOCK_LIST_KEY = "block_list";
const MILLIS_PER_MIN = 1000 * 60;
var darkModeEnabled = readDarkModePreference();
export var inMemoryBlockList = getBlockList();
enableDarkMode(darkModeEnabled);

export function getMessagesCache() {
  let storageItem = localStorage.getItem(SENT_MESSAGES_KEY);
  let sentMessages;
  if (storageItem === null) {
    sentMessages = new Map();
  } else {
    sentMessages = new Map(JSON.parse(storageItem));
  }

  return validateMessageCache(sentMessages);
}

function validateMessageCache(sentMessages) {
  for (const [key, value] of sentMessages.entries()) {
    if (Date.now() - value > 5 * MILLIS_PER_MIN) {
      sentMessages.delete(key);
    }
  }

  localStorage.setItem(SENT_MESSAGES_KEY, JSON.stringify([...sentMessages]));
  return sentMessages;
}

export function cacheMessage(newMessage) {
  let sentMessages = getMessagesCache();
  sentMessages.set(newMessage, Date.now());
  localStorage.setItem(SENT_MESSAGES_KEY, JSON.stringify([...sentMessages]));
}

/* Dark Mode */

function saveDarkModePreference(darkModeEnabled) {
  localStorage.setItem(DARK_MODE_KEY, JSON.stringify(darkModeEnabled));
}

export function readDarkModePreference() {
  var darkModeEnabled = localStorage.getItem(DARK_MODE_KEY);
  if (
    darkModeEnabled === "undefined" ||
    darkModeEnabled === null ||
    darkModeEnabled == null
  ) {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      darkModeEnabled = true;
    } else {
      darkModeEnabled = false;
    }
    saveDarkModePreference(darkModeEnabled);
  } else {
    darkModeEnabled = JSON.parse(darkModeEnabled);
  }
  return darkModeEnabled;
}

export function hasSeenOnboarding() {
  var hasSeenOnboarding = localStorage.getItem(ONBOARDING_SHOWN_KEY);
  if (hasSeenOnboarding == null) {
    return false;
  }

  return hasSeenOnboarding;
}

export function saveHasSeenOnboarding() {
  localStorage.setItem(ONBOARDING_SHOWN_KEY, true);
}

export function toggleDarkMode() {
  darkModeEnabled = !darkModeEnabled;
  saveDarkModePreference(darkModeEnabled);
  enableDarkMode();
  return darkModeEnabled;
}

export function setDarkMode(isDarkMode) {
  if (isDarkMode) {
    darkModeEnabled = true;
  } else {
    darkModeEnabled = false;
  }
  saveDarkModePreference(darkModeEnabled);
  enableDarkMode();
}

export function getOnboardingIndex() {
  var onboardingIndex = localStorage.getItem(ONBOARDING_INDEX);
  if (onboardingIndex == null) {
    return 0;
  }

  return onboardingIndex;
}

export function setOnboardingIndex(index) {
  localStorage.setItem(ONBOARDING_INDEX, index);
}

function enableDarkMode() {
  if (darkModeEnabled) {
    // Set screen to black
    document.documentElement.style.setProperty("--clr-prim", "#1e1e1e");
    document.documentElement.style.setProperty("--clr-sec", "#e1e1e1");
    document.documentElement.style.setProperty("--clr-tert", "#1e1e1e");
    document.documentElement.style.setProperty("--clr-shadow", "#000");
    document.documentElement.style.setProperty("--clr-bg", "#121212");
    document.documentElement.style.setProperty("--clr-invert-bg", "#fdfdfd");
    document.documentElement.style.setProperty("--clr-cached", "#999999");
    //DARK_MODE_BUTTON.textContent = "Light Mode";
  } else {
    // Set screen to white
    document.documentElement.style.setProperty("--clr-prim", "#fff");
    document.documentElement.style.setProperty("--clr-sec", "#000");
    document.documentElement.style.setProperty("--clr-tert", "#ebedef");
    document.documentElement.style.setProperty("--clr-shadow", "#dedede");
    document.documentElement.style.setProperty("--clr-bg", "#fdfdfd");
    document.documentElement.style.setProperty("--clr-invert-bg", "#121212");
    document.documentElement.style.setProperty("--clr-cached", "#777777");
    //DARK_MODE_BUTTON.textContent = "Dark Mode";
  }
}

export function getBlockList() {
  let storageItem = localStorage.getItem(BLOCK_LIST_KEY);
  let blockList;
  if (storageItem === null) {
    blockList = new Set();
  } else {
    blockList = new Set(JSON.parse(storageItem));
  }

  return blockList;
}

export function addToBlockList(newBlock) {
  let blockList = getBlockList();
  blockList.add(newBlock);
  inMemoryBlockList = blockList;
  localStorage.setItem(BLOCK_LIST_KEY, JSON.stringify([...blockList]));
}

export function removeFromBlockList(block) {
  let blockList = getBlockList();
  blockList.delete(block);
  localStorage.setItem(BLOCK_LIST_KEY, JSON.stringify([...blockList]));
}
