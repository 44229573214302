import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { createMessage } from '../../../store/messages-actions';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

function inset(containerRect) {
  const hInset = containerRect.width * 0.01;
  const vInset = containerRect.height * 0.01;

  return new DOMRect(
    containerRect.left + hInset,
    containerRect.top + vInset,
    containerRect.width - hInset * 2,
    containerRect.height - vInset * 2
  );
}

function HighlightedComponents() {
  const messages = useSelector(
    (state) => Object.values(state.messages.byId),
    (prevState, nextState) => {
      return false;
    }
  );
  const ui = useSelector(
    (state) => Object.values(state.ui),
    (prevState, nextState) => {
      return false;
    }
  );
  const containerRect = inset(
    useSelector(
      (state) => state.containerRect,
      (prevState, nextState) => {
        return false;
      }
    )
  );

  return (
    <Container>
      {messages.map((message) => (
        <div
          key={message.id}
          style={{
            position: 'absolute',
            top: message.rect.top,
            left: message.rect.left,
            width: message.rect.width,
            height: message.rect.height,
            opacity: 0.25,
            backgroundColor: 'red',
          }}></div>
      ))}
      {ui.map((rect) => (
        <div
          key={Math.random()}
          style={{
            position: 'absolute',
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            backgroundColor: 'blue',
            opacity: 0.25,
          }}></div>
      ))}
      <div
        style={{
          position: 'absolute',
          top: containerRect.top,
          left: containerRect.left,
          width: containerRect.width,
          height: containerRect.height,
          backgroundColor: 'green',
          opacity: 0.25,
        }}></div>
    </Container>
  );
}

export default HighlightedComponents;
