import { getOnboardingIndex } from "../utils/localstorage";
import {
  INCREMENT_ONBOARDING,
  ASSIGNED_TOOLTIP_TO_MESSAGE,
} from "./onboarding-actions";

const messageToolTipIndexes = new Set([0, 2, 3]);

/*
Onboarding master list:
0. All messages from real people
1. Type here to enter a message
2. All messages disappear automatically
3. Each person has their own color
4. Click here for settings
*/

export default (
  state = { index: getOnboardingIndex(), needsMessage: true },
  action
) => {
  switch (action.type) {
    case INCREMENT_ONBOARDING:
      state.index++;
      if (messageToolTipIndexes.has(state.index)) {
        state.needsMessage = true;
      } else {
        state.needsMessage = false;
      }
      return state;
    case ASSIGNED_TOOLTIP_TO_MESSAGE:
      state.needsMessage = false;
      return state;
    default:
      return state;
  }
};
