import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useWindowSize from '../../../hooks/useWindowSize';
import useKeyPress from '../../../hooks/useKeyPress';
import InputBox from './InputBox';
import SubmitButton from './SubmitButton';
import { Container as MockContainer } from './InputContainer';

const Container = styled(MockContainer)`
  -webkit-appearance: none;
  position: absolute;
  bottom: ${(props) => props.bottom};
  z-index: ${(props) => props.z_index};
  transition: ${(props) => props.transition};
  filter: none;
  padding-bottom: 3rem;
  transform: rotateZ(360deg);
  will-change: transform;
`;

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function IOSInputContainer(props) {
  const [inputText, setInputText] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  const [inputActive, setInputActive] = useState(false);
  const [inputActiveSwitch, setInputActiveSwitch] = useState(false);
  const { windowHeight, visualViewportHeight } = useWindowSize();

  const iOSInputBox = useRef();

  const styles = useRef({
    transition: 'none',
    bottom: '9999px',
    z_index: '-1',
  });

  const prevVVH = useRef(0);

  const enabled = useRef(true);

  useEffect(() => {
    enabled.current = props.enabled;
  }, [props.enabled]);

  function submitMessage() {
    if (enabled.current) {
      props.sendMessage(iOSInputBox.current.value);
      setInputText('');
    }
  }

  useKeyPress('Enter', () => {
    submitMessage();
  });

  useEffect(() => {
    if (inputFocused && !inputActive) {
      styles.current.transition = 'none';
      styles.current.bottom = '0px';
      styles.current.z_index = '100';
    } else if (!inputFocused && inputActive) {
      styles.current.transition = 'all 0.01s ease';
      styles.current.bottom = '9999px';
      styles.current.z_index = '-1';
      setInputActive(false);
    }
  }, [inputFocused, inputActive]);

  useEffect(() => {
    function setRefs() {
      styles.current.transition = 'all 0.245s cubic-bezier(.21,.7,.38,.85)';
      styles.current.bottom = `${
        windowHeight - visualViewportHeight - convertRemToPixels(2.25)
      }px`;
      prevVVH.current = visualViewportHeight;
    }
    if (windowHeight !== visualViewportHeight && !inputActive && inputFocused) {
      setRefs();
      setInputActive(true);
    } else if (
      visualViewportHeight !== prevVVH.current &&
      inputActive &&
      inputFocused
    ) {
      setRefs();
      setInputActiveSwitch(true);
    }
  }, [windowHeight, visualViewportHeight, inputActive, inputFocused]);

  useEffect(() => {
    if (inputActiveSwitch) {
      setInputActiveSwitch(false);
    }
  }, [inputActiveSwitch]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        iOSInputBox.current.blur();
      }
    };
    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    );

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false
      );
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (
        prevVVH.current !== window.visualViewport.height &&
        inputFocused &&
        inputActive
      ) {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 50);
      }
    };
    window.visualViewport.addEventListener('resize', handleResize);
    return () => {
      window.visualViewport.removeEventListener('resize', handleResize);
    };
  }, [inputActive, inputFocused]);

  return (
    <React.Fragment>
      <MockContainer>
        <InputBox
          value={inputText}
          onChange={(text) => {
            setInputText(text);
          }}
          onFocus={() => {
            iOSInputBox.current.focus();
          }}
          disabled={inputFocused}
        />
        <SubmitButton onSubmit={submitMessage} enabled={props.enabled} />
      </MockContainer>
      <Container
        bottom={styles.current.bottom}
        z_index={styles.current.z_index}
        transition={styles.current.transition}>
        <InputBox
          ref={iOSInputBox}
          value={inputText}
          onChange={(text) => {
            setInputText(text);
          }}
          onFocus={() => {
            setInputFocused(true);
          }}
          onBlur={() => {
            setInputFocused(false);
          }}
        />
        <SubmitButton
          onSubmit={(e) => {
            submitMessage();
          }}
          enabled={props.enabled}
        />
      </Container>
    </React.Fragment>
  );
}

export default IOSInputContainer;
