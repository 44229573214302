import { combineReducers } from 'redux';
import {
  ADD_MESSAGE,
  UPDATE_MESSAGE,
  DELETE_MESSAGE,
  UPDATE_MESSAGES,
  CACHE_MESSAGE,
  POP_MESSAGE,
} from './messages-actions';

const messagesById = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MESSAGE:
    case ADD_MESSAGE:
      return {
        ...state,
        [action.payload.message.id]: action.payload.message,
      };
    case UPDATE_MESSAGES:
      const updateMessagesState = { ...state };
      action.payload.messages.forEach((message) => {
        updateMessagesState[message.id] = message;
      });
      return updateMessagesState;
    case DELETE_MESSAGE:
      const removeMessagesState = { ...state };
      delete removeMessagesState[action.payload.id];
      return removeMessagesState;
    default:
      return state;
  }
};

const allMessages = (state = [], action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return state.concat(action.payload.message.id);
    case DELETE_MESSAGE:
      return state.filter((id) => id !== action.payload.id);
    default:
      return state;
  }
};

const CACHE_LENGTH = 5;

const cacheMessages = (state = [], action) => {
  switch (action.type) {
    case CACHE_MESSAGE:
      if (state.length >= CACHE_LENGTH) {
        return state.slice(1).concat(action.payload.message);
      }
      return state.concat(action.payload.message);
    case POP_MESSAGE:
      return state.slice(0, -1);
    default:
      return state;
  }
};

export default combineReducers({
  byId: messagesById,
  allIds: allMessages,
  cached: cacheMessages,
});
