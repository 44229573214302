import { UPDATE_CONTAINER } from './container-actions';

const initialState = new DOMRect(0, 0, 0, 0);

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTAINER:
      return action.payload.containerRect;
    default:
      return state;
  }
};
