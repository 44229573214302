// Generate a base react component for me:
import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  color: white;
  width: 100%;
  display: block;
`;

const CustomToastWithLink = (props) => {
  return (
    <StyledLink href={props.link} target="_blank" rel="noreferrer noopener">
      {props.text}
    </StyledLink>
  );
};

export default CustomToastWithLink;
