import styled from "styled-components";
import { Times } from "@styled-icons/fa-solid/Times";
import { useRef } from "react";
import {
  readDarkModePreference,
  toggleDarkMode,
} from "./../../utils/localstorage";
import { getAnalytics, logEvent } from "firebase/analytics";
import Modal from "styled-react-modal";
import { device } from "./../../constants/device-sizes";
import ModalButton from "./ModalButton";

const CloseButton = styled(Times)`
  cursor: pointer;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 1rem;
  right: 1rem;
  margin: 1rem 1rem;
  font-size: 2rem;
  transition: all 0.2s linear;
  color: var(--clr-sec);
  :hover {
    color: red;
  }
`;

const SettingsList = styled.ul`
  list-style-type: none;
  margin: 0 0;
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
`;

const SettingsItem = styled.li`
  width: 50%;
  margin: auto auto 2rem;
  padding: 1rem;
  background-color: var(--clr-prim);
  border-radius: 2rem;
  color: var(--clr-sec);
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    margin: -1rem;
    padding: 1rem;
  }
`;

const ModalSC = Modal.styled`
  width: 100%;
  padding: 1rem;
  @media ${device.tablet} {
    width: 50%;
    position: auto;
    top: auto;
  }
  top: 0px;
  position: absolute;
`;

const ModalContent = styled.div`
  padding: 16px;
  background-color: var(--clr-bg);
  width: 100%;
  border-radius: 12px;
  shadow-opacity: 0.18;
  shadow-radius: 4px;
  shadow-color: #000;
  shadow-offset: 0px 4px;
  elevation: 10;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    margin: -1rem;
    padding: 1rem;
  }
`;

const SettingsMenu = (props) => {
  const darkModeButton = useRef();
  function getDarkModePref() {
    return readDarkModePreference();
  }

  function menuToggleDarkMode() {
    let darkModeEnabled = toggleDarkMode();
    darkModeButton.current.innerText = darkModeEnabled
      ? "Light Mode"
      : "Dark Mode";
  }

  return (
    <ModalSC isOpen={props.visible} onBackgroundClick={props.onClose}>
      <ModalContent>
        <CloseButton onClick={props.closeSettingsMenu} />
        <SettingsList>
          <SettingsItem
            onClick={() => {
              const analytics = getAnalytics();
              logEvent(analytics, "instructions_clicked");
              props.openInstructions();
            }}>
            Instructions
          </SettingsItem>
          <SettingsItem onClick={menuToggleDarkMode} ref={darkModeButton}>
            {getDarkModePref() ? "Light Mode" : "Dark Mode"}
          </SettingsItem>
          <SettingsItem>
            <a
              href='https://www.reddit.com/r/VentScape/'
              target='_blank'
              rel='noreferrer noopener'>
              Leave feedback for VentScape on Reddit!
            </a>
          </SettingsItem>
          <SettingsItem
            onClick={() => {
              const analytics = getAnalytics();
              logEvent(analytics, "discord_clicked", { referrer: "settings" });
            }}>
            <a
              href='https://discord.gg/rM7g5Zusmr'
              target='_blank'
              rel='noreferrer noopener'>
              Join the VentScape Discord
            </a>
          </SettingsItem>
          <SettingsItem>
            <a
              href='https://www.buymeacoffee.com/ventscape'
              target='_blank'
              rel='noreferrer noopener'>
              Donate to keep VentScape running!
            </a>
          </SettingsItem>
        </SettingsList>
      </ModalContent>
    </ModalSC>
  );
};

export default SettingsMenu;
