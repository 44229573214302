// var retries = 0;
// var totalTries = 0;
export function getPositionForNewDiv(newDiv, currentDivs, boundingRect) {
  var pos = partitionAlgorithm(
    newDiv,
    currentDivs,
    boundingRect,
    boundingRect,
    0
  );

  let verified = verifyDivPos(
    new DOMRect(pos[0], pos[1], newDiv.width, newDiv.height),
    currentDivs,
    boundingRect,
    0
  );
  //console.log("verified", verified);
  // This retries / total tries stuff is for trying to figure out which cutoff in partitionAlgorithm
  // allows for the lowest chance of overlap
  //console.log("POS:      " + pos);
  //console.log("VERIFIED: " + verified);
  //if (pos[0] !== verified[0]) {
  //retries++;
  //}
  // TODO: adjust from 0.01 cutoff in partition algoruithm
  //totalTries++;
  //console.log("RETRY PROP: " + retries.toFixed() / totalTries);
  return verified;
}

function partitionAlgorithm(
  newDiv,
  currentDivs,
  boundingRect,
  outerBoundingRect,
  depth
) {
  let rect1;
  let rect2;
  if (boundingRect.height > boundingRect.width) {
    let midPoint = boundingRect.top + boundingRect.height / 2;
    rect1 = new DOMRect(
      boundingRect.left,
      boundingRect.top,
      boundingRect.width,
      boundingRect.height / 2
    );
    rect2 = new DOMRect(
      boundingRect.left,
      midPoint,
      boundingRect.width,
      boundingRect.height / 2
    );
  } else {
    let midPoint = boundingRect.left + boundingRect.width / 2;
    rect1 = new DOMRect(
      boundingRect.left,
      boundingRect.top,
      boundingRect.width / 2,
      boundingRect.height
    );
    rect2 = new DOMRect(
      midPoint,
      boundingRect.top,
      boundingRect.width / 2,
      boundingRect.height
    );
  }

  let rect1Stack = [];
  let rect2Stack = [];
  let rect1TIS = 0;
  let rect2TIS = 0;
  for (var div of currentDivs) {
    if (doRectanglesIntersect(div, rect1)) {
      rect1Stack.push(div);
      rect1TIS += rectIntersectionArea(div, rect1);
    }
    if (doRectanglesIntersect(div, rect2)) {
      rect2Stack.push(div);
      rect2TIS += rectIntersectionArea(div, rect2);
    }
  }

  let rect1FilledProportion = rect1TIS / (rect1.width * rect1.height);
  let rect2FilledProportion = rect2TIS / (rect2.width * rect2.height);
  if (rect1FilledProportion === rect2FilledProportion) {
    if (Math.random() < 0.5) {
      return placeDivInCorner(newDiv, currentDivs, rect1, outerBoundingRect);
    } else {
      return placeDivInCorner(newDiv, currentDivs, rect2, outerBoundingRect);
    }
  }

  if (rect1FilledProportion < 0.01) {
    return placeDivInCorner(newDiv, rect1Stack, rect1, outerBoundingRect);
  } else if (rect2FilledProportion < 0.01 || depth >= 4) {
    return placeDivInCorner(newDiv, rect2Stack, rect2, outerBoundingRect);
  } else if (rect1FilledProportion < rect2FilledProportion) {
    return partitionAlgorithm(
      newDiv,
      rect1Stack,
      rect1,
      outerBoundingRect,
      ++depth
    );
  } else {
    return partitionAlgorithm(
      newDiv,
      rect2Stack,
      rect2,
      outerBoundingRect,
      ++depth
    );
  }
}

function placeDivInCorner(
  newDiv,
  currentDivs,
  boundingRect,
  outerBoundingRect
) {
  let nearestLeft = boundingRect.left;
  let nearestRight = boundingRect.right;
  let nearestTop = boundingRect.top;
  let nearestBottom = boundingRect.bottom;
  let centerX = boundingRect.left + boundingRect.width / 2;
  let centerY = boundingRect.top + boundingRect.height / 2;

  for (var div of currentDivs) {
    if (div.right < centerX && div.right > nearestLeft) {
      nearestLeft = div.right;
    }
    if (div.left > centerX && div.left < nearestRight) {
      nearestRight = div.left;
    }
    if (div.bottom < centerY && div.bottom > nearestTop) {
      nearestTop = div.bottom;
    }
    if (div.top > centerY && div.top < nearestBottom) {
      nearestBottom = div.top;
    }
  }

  // Pick corner closest to edge
  let leftDFromEdge = nearestLeft - boundingRect.left;
  let rightDFromEdge = boundingRect.right - nearestRight;
  let topDFromEdge = nearestTop - boundingRect.top;
  let bottomDFromEdge = boundingRect.bottom - nearestBottom;
  let isOnLeftSide;
  let isOnTopSide;
  let newleft;
  let newtop;

  if (leftDFromEdge === rightDFromEdge) {
    isOnLeftSide = Math.random() < 0.5;
  } else {
    isOnLeftSide = leftDFromEdge < rightDFromEdge;
  }

  if (topDFromEdge === bottomDFromEdge) {
    isOnTopSide = Math.random() < 0.5;
  } else {
    isOnTopSide = topDFromEdge < bottomDFromEdge;
  }

  if (isOnLeftSide) {
    let outerRight = outerBoundingRect.left + outerBoundingRect.width;
    newleft =
      nearestLeft + newDiv.width < outerRight
        ? nearestLeft
        : outerRight - newDiv.width;
  } else {
    newleft = Math.max(nearestRight - newDiv.width, outerBoundingRect.left);
  }
  if (isOnTopSide) {
    let outerBottom = outerBoundingRect.top + outerBoundingRect.height;
    newtop =
      nearestTop + newDiv.height < outerBottom
        ? nearestTop
        : outerBottom - newDiv.height;
  } else {
    newtop = Math.max(nearestBottom - newDiv.height, outerBoundingRect.top);
  }

  return [newleft, newtop];
}

function verifyDivPos(newDiv, currentDivs, boundingRect, tryCount) {
  if (tryCount >= 30) {
    return [newDiv.left, newDiv.top];
  }

  for (var div of currentDivs) {
    if (doRectanglesIntersect(newDiv, div)) {
      let newleft =
        boundingRect.left + Math.random() * (boundingRect.width - newDiv.width);
      let newtop =
        boundingRect.top +
        Math.random() * (boundingRect.height - newDiv.height);
      console.log(
        'Generating random pos ' +
          newleft +
          'x ' +
          newtop +
          'y after ' +
          tryCount +
          ' tries'
      );
      return verifyDivPos(
        new DOMRect(newleft, newtop, newDiv.width, newDiv.height),
        currentDivs,
        boundingRect,
        ++tryCount
      );
    }
  }

  // No intersections, good to go!
  return [newDiv.left, newDiv.top];
}

function rectIntersectionArea(rect1, rect2) {
  let x_overlap = Math.max(
    0,
    Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left)
  );
  let y_overlap = Math.max(
    0,
    Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top)
  );
  let overlapArea = x_overlap * y_overlap;
  return overlapArea;
}

export function doRectanglesIntersect(a, b) {
  return (
    a.left < b.right && a.right > b.left && a.top < b.bottom && a.bottom > b.top
  );
}
