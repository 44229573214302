import React from 'react';
import styled from 'styled-components';
import { PaperPlane } from '@styled-icons/fa-solid';

const Button = styled(PaperPlane)`
  width: 2rem;
  height: 2rem;
  color: ${(props) => (props.enabled ? 'var(--clr-purple)' : '#aaa')};
  padding: 0.25rem 0.25rem;
  font-size: var(--fs-h3);
  :focus {
    outline-width: 0px;
    box-shadow: 0 0 0 2pt #ccc;
  }
  :hover {
    transform: rotate(-15deg);
  }
  transition: transform 0.2s linear;
  cursor: pointer;
`;

function SubmitButton(props) {
  return (
    <Button
      enabled={props.enabled}
      onClick={props.onSubmit}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    />
  );
}

export default SubmitButton;
