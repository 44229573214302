import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import './messagesContainer.css';
import VentMessage from './ventMessage';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

function MessagesContainer(vars) {
  const container = useRef();
  const refs = useRef(new Map());
  const BASE_FONT_SIZE =
    parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue('--fs-body')
    ) * parseFloat(getComputedStyle(document.documentElement).fontSize);

  function addRefToMap(ref, id) {
    if (ref && !refs.current.has(id)) {
      refs.current.set(id, ref);
    }
  }

  function onRemove(id) {
    refs.current.delete(id);
    vars.onRemove(id);
  }

  // Never allow more than 40 messages to appear on screen at once
  useEffect(() => {
    if (vars.messages.size > 40) {
      onRemove(vars.messages.get(Array.from(vars.messages.keys())[0]).id);
    }
  }, [vars.messages]);

  function isMobile() {
    return window.innerWidth < 768;
  }

  // Setting this up weirdly since we can't get the amount of messages in room when a message comes in
  // For some reason, the messages variable is always an empty map within that set up useEffect()
  function getMsgFontSize(msg, curMsgCount) {
    if (msg.baseSize) {
      return msg.baseSize;
    }
    if (isMobile()) {
      var divCountMultiplier = 1.342 - curMsgCount * 0.043;
      var msgLengthMultiplier = 2.01 - 0.269 * Math.log(msg.messageText.length);
    } else {
      var divCountMultiplier = 1.357 - curMsgCount * 0.0366;
      var msgLengthMultiplier =
        1.9855 - 0.239 * Math.log(msg.messageText.length);
    }

    let msgSizeMultiplier = msg.size !== undefined ? msg.size : 1.0;
    let finalSize = Math.max(
      divCountMultiplier *
        msgLengthMultiplier *
        msgSizeMultiplier *
        BASE_FONT_SIZE,
      BASE_FONT_SIZE * 0.5
    );
    msg.baseSize = finalSize;
    return finalSize;
  }

  return (
    <Container ref={container}>
      {Array.from(vars.messages.values()).map((msg) => (
        <VentMessage
          key={msg.id}
          msg={msg}
          baseSize={getMsgFontSize(msg, refs.current.size)}
          msgDivs={refs.current}
          container={container}
          addRefToMap={addRefToMap}
          ref={(ref) => {
            addRefToMap(ref, msg.id);
          }}
          onRemove={onRemove}
          onMessageClicked={vars.onMessageClicked}
        />
      ))}
    </Container>
  );
}

export default MessagesContainer;
