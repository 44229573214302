import React from "react";
import styled from "styled-components";
import { isiOSDevice } from "../../../constants/deviceType";
import { MAX_MESSAGE_LENGTH } from "../../../constants/message-constants";

const Input = styled.input`
  margin: 0;
  margin-right: 0.25rem;
  flex-grow: 1;
  filter: none;
  border-radius: 1rem;
  font-size: var(--fs-body);
  background-color: var(--clr-tert);
  color: var(--clr-sec);
  padding: 0.3rem 0.75em;
  border: 0px;
  outline: none;
  line-height: 1;
`;

const InputBox = React.forwardRef((props, ref) => {
  return (
    <Input
      // Do not autofocus on iOS devices due to weird input box behavior
      autoFocus={isiOSDevice() ? false : true}
      autoComplete="off"
      autoCorrect="off"
      ref={ref}
      placeholder="What's on your mind?"
      maxLength={MAX_MESSAGE_LENGTH}
      tabIndex="-1"
      disabled={props.disabled}
      value={props.value}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  );
});

export default InputBox;
