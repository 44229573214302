export const INCREMENT_ONBOARDING = "INCREMENT_ONBOARDING";
export const ASSIGNED_TOOLTIP_TO_MESSAGE = "ASSIGNED_TOOOLTIP_TO_MESSAGE";

export const incrementOnboarding = () => {
  return {
    type: INCREMENT_ONBOARDING,
  };
};

export const assignedTooltipToMessage = () => {
  return {
    type: ASSIGNED_TOOLTIP_TO_MESSAGE,
  };
};
