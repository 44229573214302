import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { createMessage } from '../../../store/messages-actions';

function DisplayRandomMessages() {
  const dispatch = useDispatch();
  useEffect(() => {
    function displayRandomMessage() {
      const message = {
        messageText: 'Chad '.repeat(Math.floor(1 + Math.random() * 9)),
        id: uuidv4(),
      };
      dispatch(createMessage(message));
      setTimeout(displayRandomMessage, 2000);
    }
    displayRandomMessage();
  }, []);

  return <React.Fragment></React.Fragment>;
}

export default DisplayRandomMessages;
