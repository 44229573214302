import React from 'react';
import styled from 'styled-components';
import { device } from '../constants/device-sizes';
import { getPositionForNewDiv } from '../utils/geometry';
import $ from 'jquery';

const VentDiv = styled.div`
  position: absolute;
  font-size: var(--fs-body);
  max-width: 45%;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  @media ${device.tablet} {
    width: 25%;
  }
  transform: scale(1);
  transition: transform 0.2s linear;
  cursor: pointer;
`;

class VentMessage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.curDiv = React.createRef();
    this.clicks = 0;
    this.onReceiveClick = this.onReceiveClick.bind(this);
  }

  getInset(rect) {
    let ratio;
    let curMsgCount = this.props.msgDivs.size;
    if (this.isMobile()) {
      ratio = 0.02;
    } else {
      let max = 0.35 - 0.2 * Math.random();
      ratio = Math.max(max - 0.035 * curMsgCount, 0.02);
    }
    let hInset = rect.width * ratio;
    let vInset = rect.height * ratio;
    return new DOMRect(
      rect.left + hInset,
      rect.top + vInset,
      rect.width - hInset * 2,
      rect.height - vInset * 2
    );
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  getMsgDuration(msgText) {
    var displayTime = 10000 + (10000 * msgText.length) / 150;
    if (this.isMobile()) {
      let curMsgCount = this.props.msgDivs.size;
      let divCountMultiplier = 1.395 - 0.0556 * curMsgCount;
      displayTime *= divCountMultiplier;
    }
    displayTime = Math.max(displayTime, 2000);
    displayTime = Math.min(displayTime, 25000);
    return displayTime;
  }

  componentDidMount() {
    // console.log("Render", this.props.msg.id, "with divs", this.props.msgDivs);
    // console.log("Render", this.props.msgDivs);
    let msg = this.props.msg;
    var curDivs = Array.from(this.props.msgDivs.keys())
      .filter((key) => key !== this.props.msg.id)
      .map((key) =>
        this.props.msgDivs.get(key).curDiv.current.getBoundingClientRect()
      );

    this.curDiv.current.style.fontSize = msg.baseSize + 'px';
    //console.log("curdivs", curDivs);
    //this.props.addRefToMap(this.curDiv, this.props.msg.id);
    let container = this.getInset(
      this.props.container.current.getBoundingClientRect()
    );

    let pos = getPositionForNewDiv(
      this.curDiv.current.getBoundingClientRect(),
      curDivs,
      container
    );
    this.curDiv.current.style.left = pos[0] + 'px';
    this.curDiv.current.style.top = pos[1] + 'px';

    $(this.curDiv.current)
      .hide()
      .fadeIn(1000)
      .fadeOut(this.getMsgDuration(msg.messageText), () => {
        //console.log("fading finished", this.props.msg.id);
        this.props.onRemove(msg.id);
      });
  }

  componentDidUpdate(prevProps) {
    //console.log("component did update", this.props.msg.id, prevProps.msg.clicks, this.props.msg.clicks);
    if (prevProps.msg.clicks < this.props.msg.clicks) {
      this.onReceiveClick(this.props.msg.clicks);
    }
  }

  onReceiveClick(clicks) {
    let result = 1.0 + 0.025 * Math.min(clicks, 10) + 0.025;
    this.curDiv.current.style.transform = 'scale(' + result + ')';
    setTimeout(() => {
      let finalSize = 1.0 + 0.025 * Math.min(clicks, 10);
      this.curDiv.current.style.transform = 'scale(' + finalSize + ')';
    }, 200);
  }

  render() {
    return (
      <VentDiv
        onClick={() => {
          this.props.onMessageClicked(this.props.msg);
        }}
        ref={this.curDiv}
        style={{
          top: this.props.msg.top,
          left: this.props.msg.left,
          color: this.props.msg.color,
          fontWeight: this.props.msg.fontWeight,
        }}>
        {this.props.msg.messageText}
      </VentDiv>
    );
  }
}

export default VentMessage;
