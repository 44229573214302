import React from 'react';
import styled from 'styled-components';
import { device } from '../constants/device-sizes';
const MainContentWrapper = styled.div`
  width: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  margin: auto auto auto auto;
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  border-radius: 0 0 2rem 2rem;
  background: var(--clr-prim);
  @media ${device.tablet} {
    width: 50%;
  }
  padding: var(--main-padding);
`;

const VentScapeTitle = styled.h2`
  text-align: center;
  margin: auto auto;
`;

function Header() {
  return (
    <MainContentWrapper>
      <MainContent>
        <VentScapeTitle>VentScape</VentScapeTitle>
      </MainContent>
    </MainContentWrapper>
  );
}

export default Header;
