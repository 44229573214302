import PhoneNumber from 'awesome-phonenumber';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import ModalButton from './ModalButton';
import TitleText from './TitleText';
import { device } from './../../constants/device-sizes';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { API_URL } from './../../constants/string-constants';
import ReactCodeInput from 'react-verification-code-input';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  browserLocalPersistence,
  setPersistence,
} from 'firebase/auth';

const ModalSC = Modal.styled`
  width: 100%;
  padding: 1rem;
  @media ${device.tablet} {
    width: 50%;
  }
`;

const ModalContent = styled.div`
  padding: 16px;
  background-color: var(--clr-bg);
  width: 100%;
  border-radius: 12px;
  shadow-opacity: 0.18;
  shadow-radius: 4px;
  shadow-color: #000;
  shadow-offset: 0px 4px;
  elevation: 10;
`;

const Description = styled.p`
  color: ${(props) => props.theme.ALT_TEXT_COLOR};
  font-size: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const PhoneInputContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const PhoneInput = styled.input`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 70%;
  max-width: 15rem;
  margin-left: 8px;
  font-size: 24px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.MODAL_INPUT_BACKGROUND};
  color: ${(props) => props.theme.TEXT_COLOR};
`;

const DropdownSC = styled(Dropdown)`
  width: 30px;
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
`;

const ErrorText = styled.p`
  color: #d00;
  font-size: 14px;
  text-align: center;
`;

const ModalButtonSC = styled.div`
  flex-grow: 1;
`;

const CodeInput = styled(ReactCodeInput)`
  font-family: var(--ff-primary);
`;

const PhoneSignUpModal = (props) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirm, setConfirm] = useState(null);
  const [continueEnabled, setContinueEnabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [verificationCodeText, setVerificationCodeText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('+1');
  const phoneInput = useRef(null);
  const codeField = useRef(null);
  const [curPage, setCurPage] = useState(0);
  const whiteListedPhoneNumber = useRef(false);
  const captchaRef = React.useRef(null);

  useEffect(() => {
    if (confirm == null) {
      setCurPage(0);
      setFocus(phoneInput, 500);
    } else {
      setCurPage(1);
      setFocus(codeField, 500);
    }
  }, [confirm]);

  useEffect(() => {
    if (confirm == null) {
      var initPn = selectedCountry + phoneNumber;
      var pn = PhoneNumber(initPn);
      // change isValid() to isPossible() if debugging so numbers like 6280000000 will work
      setContinueEnabled(pn.isValid());

      if (phoneNumber.length > 8 && phoneNumber.includes('+')) {
        checkPhoneWhitelist();
      }
    } else {
      setContinueEnabled(verificationCodeText.length === 6);
      if (verificationCodeText.length === 6) {
        verify();
      }
    }
  }, [phoneNumber, verificationCodeText, confirm]);

  async function checkPhoneWhitelist() {
    const token = await getAuth().currentUser.getIdToken();
    const result = await fetch(
      API_URL + 'phone-number?phone_number=' + phoneNumber.replace('+', '%2b'),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }
    );

    if (result.status === 200) {
      const responseJson = await result.json();
      whiteListedPhoneNumber.current = responseJson.phoneNumberFound;
      setContinueEnabled(responseJson.phoneNumberFound);
    } else {
      whiteListedPhoneNumber.current = false;
      setContinueEnabled(false);
    }
  }

  useEffect(() => {
    setFocus(phoneInput, 100);
    if (props.visible) {
      // modalLargeToastRef.current.show({
      //   type: 'info',
      //   text1: 'Heads Up',
      //   text2:
      //     "Due to moderation concerns, we're only accepting phone sign ups from a few English speaking countries for now. If you want to sign up but can't, email me gabe@ventscape.life",
      //   topOffset: 48,
      //   height: 100,
      //   visibilityTime: 30000,
      // });
    }
  }, [props.visible]);

  // This is the only way I can get auto focus to work. The auto focus prop just will not work and I don't know why
  function setFocus(viewRef, delay) {
    setTimeout(() => {
      if (viewRef.current) {
        viewRef.current.focus();
      }
    }, delay);
  }

  async function onPressContinue() {
    console.log(selectedCountry);
    if (continueEnabled && !isLoading) {
      setErrorText('');
      if (confirm == null) {
        setLoading(true);
        var fullPhoneNumber = whiteListedPhoneNumber.current
          ? phoneNumber
          : selectedCountry + phoneNumber;
        console.log('sending phone request ' + fullPhoneNumber);
        const auth = getAuth();
        const recaptcher = new RecaptchaVerifier(
          captchaRef.current,
          {
            size: 'invisible',
            callback: (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              //onSignInSubmit();
            },
          },
          auth
        );
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            console.log('sucess');
            return signInWithPhoneNumber(auth, fullPhoneNumber, recaptcher)
              .catch((error) => {
                console.log(error);
                setErrorText(
                  "Unknown error. Make sure you're connected to the internet"
                );
                setLoading(false);
              })
              .then((confirmation) => {
                console.log('received confirmation', confirmation);
                setLoading(false);
                setConfirm(confirmation);
              });
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log('error', error);
          });
      } else {
        verify();
      }
    }
  }

  async function verify() {
    //analytics().logEvent('phone_verification_attempt', {});
    setLoading(true);
    confirm
      .confirm(verificationCodeText)
      .catch((error) => {
        console.log(error.code, error.message, error);
        if (error.code === 'auth/invalid-verification-code') {
          setErrorText('Invalid code. Please try again.');
        } else {
          setErrorText(
            "Unknown error. Make sure you're connected to the internet"
          );
        }

        setLoading(false);
      })
      .then((result) => {
        // analytics().logSignUp({
        //   method: 'phone',
        // });
        if (result !== undefined) {
          props.onSuccess();
          props.onClose();
        }
        setLoading(false);
      });
  }

  function onPressCancel() {
    if (confirm == null) {
      props.onClose();
    } else {
      setErrorText('');
      setConfirm(null);
    }
  }

  const countryPickerOptions = [
    { label: '🇬🇧 +44', value: '+44' },
    { label: '🇺🇸/🇨🇦 +1', value: '+1' },
    { label: '🇦🇺 +61', value: '+61' },
    { label: '🇳🇿 +64', value: '+64' },
    { label: '🇮🇪 +353', value: '+353' },
  ];

  function getCurrentPage() {
    if (curPage === 0) {
      return (
        <Page collapsable={false} key='1'>
          <TitleText>Just one sec!</TitleText>
          <Description>
            We’ll send your message in a sec, we just need your phone number to
            verify that you’re a real person.{'\n\n'}Let’s keep VentScape spam
            free!
          </Description>
          <PhoneInputContainer>
            <Dropdown
              options={countryPickerOptions}
              value={selectedCountry}
              onChange={(option) => {
                setSelectedCountry(option.value);
              }}
            />
            <PhoneInput
              ref={phoneInput}
              onInput={(text) => {
                setPhoneNumber(text.target.value);
              }}
              value={phoneNumber}
              keyboardType='phone-pad'
              autoCompleteType='tel'
              placeholder='555-555-5555'></PhoneInput>
          </PhoneInputContainer>
        </Page>
      );
    } else {
      return (
        <Page collapsable={false} key='2'>
          <TitleText>Verify</TitleText>
          <Description>
            Just put in the 6 digit verification code we sent you and we’ll get
            your message sent right away
          </Description>

          <PhoneInputContainer>
            <CodeInput
              fields={6}
              type='number'
              fieldWidth={40}
              onChange={setVerificationCodeText}></CodeInput>
          </PhoneInputContainer>
        </Page>
      );
    }
  }

  return (
    <ModalSC isOpen={props.visible} onBackgroundClick={props.onClose}>
      <ModalContent>
        {getCurrentPage()}
        <ErrorText>{errorText}</ErrorText>
        <ButtonContainer>
          <ModalButtonSC
            width='45%'
            as={ModalButton}
            isAffirmative={false}
            onClick={onPressCancel}>
            Cancel
          </ModalButtonSC>
          <ModalButtonSC
            width='45%'
            as={ModalButton}
            isLoading={isLoading}
            enabled={continueEnabled}
            onClick={onPressContinue}>
            {isLoading ? '' : 'Continue'}
          </ModalButtonSC>
        </ButtonContainer>
        <div id='recaptcha-container' ref={captchaRef}></div>
      </ModalContent>
    </ModalSC>
  );
};

export default PhoneSignUpModal;
