import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react';
import 'react-dropdown/style.css';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { saveHasSeenOnboarding } from '../../utils/localstorage';
import { device } from './../../constants/device-sizes';
import ModalButton from './ModalButton';
import TitleText from './TitleText';

const ModalSC = Modal.styled`
  width: 100%;
  padding: 1rem;
  @media ${device.tablet} {
    width: 50%;
    position: auto;
    top: auto;
  }
  top: 0px;
  position: absolute;
`;

const ModalContent = styled.div`
  padding: 16px;
  background-color: var(--clr-bg);
  width: 100%;
  border-radius: 12px;
  shadow-opacity: 0.18;
  shadow-radius: 4px;
  shadow-color: #000;
  shadow-offset: 0px 4px;
  elevation: 10;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    margin: -1rem;
    padding: 1rem;
  }
`;

const SubTitle = styled.p`
  text-align: center;
  color: var(--clr-sec);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  color: var(--clr-sec);
  font-size: 1rem;
  margin: 0;
  @media ${device.tablet} {
    font-size: 1.2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

const ModalButtonSC = styled.div`
  cursor: pointer;
  flex-grow: 1;
`;

const OnboardingModal = (props) => {
  function onPressContinue() {
    const analytics = getAnalytics();
    logEvent(analytics, 'onboarding_modal_closed');
    saveHasSeenOnboarding();
    props.onClose();
  }

  return (
    <ModalSC isOpen={props.visible} onBackgroundClick={props.onClose}>
      <ModalContent>
        <TitleText>Welcome to VentScape</TitleText>
        <SubTitle>
          <i>Vent, chat, banter and see others doing the same in real-time</i>
        </SubTitle>
        <Description>
          • When you send a message, everyone on VentScape will see the message
          appear in real-time
        </Description>
        <Description>
          • All messages disappear automatically after ~10 seconds
        </Description>
        <Description>• Everyone is 100% anonymous</Description>
        <Description>
          • Use the buttons in the top right to replay old messages, replay
          newly missed messages, and access settings
        </Description>
        <SubTitle>Have fun, and be (mostly) civil please!</SubTitle>
        <ButtonContainer>
          <ModalButtonSC width='45%' as={ModalButton} onClick={onPressContinue}>
            Let's go!
          </ModalButtonSC>
        </ButtonContainer>
      </ModalContent>
    </ModalSC>
  );
};

export default OnboardingModal;
