import React, { useRef, useLayoutEffect, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { updateContainer } from "../../store/container-actions";
import Message from "./Message";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

function MessagesContainer(props) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [rect, setRect] = useState(new DOMRect(0, 0, 0, 0));

  useLayoutEffect(() => {
    if (containerRef) {
      setRect(containerRef.current.getBoundingClientRect());
    }
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      let rect = containerRef.current.getBoundingClientRect();
      if (
        window.visualViewport &&
        window.innerHeight !== window.visualViewport.height
      ) {
        rect = new DOMRect(
          0,
          window.visualViewport.offsetTop,
          rect.width,
          rect.height -
            (window.innerHeight - window.visualViewport.height) -
            window.visualViewport.offsetTop
        );
      }
      setRect(rect);
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", handleResize);
    } else {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      return () => {
        if (window.visualViewport) {
          window.visualViewport.removeEventListener("resize", handleResize);
        } else {
          window.removeEventListener("resize", handleResize);
        }
      };
    };
  }, []);

  useEffect(() => {
    dispatch(updateContainer(rect));
  }, [dispatch, rect]);

  const messages = useSelector(
    (state) => Object.values(state.messages.byId),
    (prevState, nextState) => {
      return prevState.length === nextState.length;
    }
  );

  const onboardingIndex = useSelector((state) => state.onboarding.index);

  return (
    <Container ref={containerRef}>
      {messages.map((message, index) => (
        <Message
          key={message.id}
          message={message}
          index={index}
          onboardingIndex={onboardingIndex}
          sendBlockedUserToServer={props.sendBlockedUserToServer}
        />
      ))}
    </Container>
  );
}

export default MessagesContainer;
