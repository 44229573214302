import { disableBodyScroll } from "body-scroll-lock";
import {
  getAuth,
  signInAnonymously,
  browserLocalPersistence,
  setPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { v4 as uuidv4 } from "uuid";
import { io } from "socket.io-client";
import styled from "styled-components";
import MessagesContainer from "./MessagesContainer";
import { API_URL } from "../../constants/string-constants";
import InputBar from "./input/InputBar";
import PhoneSignUpModal from "./PhoneSignUpModal";
import {
  createMessage,
  MESSAGE_STATUS_CACHED,
  MESSAGE_STATUS_NEW,
} from "../../store/messages-actions";
import { useDispatch, useSelector } from "react-redux";
import SettingsMenu from "./SettingsMenu";
import ButtonContainer from "./ButtonContainer";
import HighlightedComponents from "./debug/HighlightedComponents";
import DisplayRandomMessages from "./debug/DisplayRandomMessages";
import { getAnalytics, logEvent } from "firebase/analytics";
import ReachOutModal from "./ReachOutModal";
import OnboardingModal from "./OnboardingModal";
import {
  hasSeenOnboarding,
  inMemoryBlockList,
  setOnboardingIndex,
} from "../../utils/localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useOnboardingTooltip from "../../hooks/useOnboardingTooltip";
import { INCREMENT_ONBOARDING } from "../../store/onboarding-actions";
import CustomToastWithLink from "./CustomToastWithLink";

const RoomContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: var(--clr-bg);
  justify-content: space-between;
  flex-direction: column;
  transition: background-color 0.2s linear;
`;

function RoomV2(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reachOutModalOpen, setReachOutModalOpen] = useState(false);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false);
  const dispatch = useDispatch();

  const roomRef = useRef();
  const hasAlreadySignedIn = useRef(false);
  const socket = useRef(null);
  const messageQueue = useRef([]);
  const historicalMessages = useRef([]);
  const [connected, setConnected] = useState(-1);
  const [sendEnabled, setSendEnabled] = useState(true);
  const [replaying, setReplaying] = useState(false);
  const [recycling, setRecycling] = useState(false);
  const messagesSentCount = useRef(0);

  const onboardingInfo = useSelector((state) => state.onboarding);

  disableBodyScroll(roomRef.current);

  document.addEventListener("visibilitychange", function (e) {
    if (document.visibilityState === "visible") {
      document.getElementById("favicon").href = "favicon.ico";
      displayQueueMessage([...messageQueue.current], 2000);
      messageQueue.current = [];
    }
  });

  function displayQueueMessage(curQueue, interval) {
    if (curQueue.length === 0) {
      setReplaying(false);
      setRecycling(false);
      return;
    }

    const message = curQueue.shift();
    let blockList = inMemoryBlockList;
    if (blockList.has(message.userId)) {
      displayQueueMessage(curQueue, interval);
      return;
    }
    message.status = MESSAGE_STATUS_CACHED;
    dispatch(createMessage(message));
    setTimeout(function () {
      displayQueueMessage(curQueue, interval);
    }, interval);
  }

  async function registerUser(token) {
    console.log("Registering " + token.substring(0, 10));
    try {
      const response = await fetch(API_URL + "users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          push_notification_token: null,
        }),
      });
      console.log("user registered", await response.json());
    } catch (err) {
      console.log("Error registering user", err);
    }
  }

  async function sendBlockedUserToServer(message) {
    const token = await getAuth().currentUser.getIdToken();
    console.log("blocking " + token.substring(0, 10));
    let strippedMessage = {
      id: message.id,
      userId: message.userId,
      messageText: message.messageText,
      createdAt: message.createdAt,
    };
    try {
      const response = await fetch(API_URL + "block", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          message: strippedMessage,
        }),
      });
      console.log("user registered", await response.json());
    } catch (err) {
      console.log("Error registering user", err);
    }
  }

  useEffect(() => {
    const auth = getAuth();
    if (hasAlreadySignedIn.current === false) {
      onAuthStateChanged(auth, (user) => {
        console.log("cur user changed");
        if (user == null) {
          signInAnonymously(auth)
            .then(async (result) => {
              // Signed in..
              registerUserAndConnectToSocket(result.user);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              // ...
            });
        } else {
          registerUserAndConnectToSocket(user);
        }
      });
    }

    function emit() {
      if (onboardingInfo.needsMessage === false) {
        setOnboardingIndex(onboardingInfo.index + 1);
        dispatch({ type: INCREMENT_ONBOARDING });
      } else {
        console.log("NOT EMITTING, NEEDS MESSAGE");
      }
    }

    let interval = setInterval(() => {
      emit();
    }, 8000);

    setTimeout(() => {
      clearInterval(interval);
    }, 60000);
  }, []);

  async function registerUserAndConnectToSocket(user) {
    if (hasAlreadySignedIn.current === false) {
      hasAlreadySignedIn.current = true;
      const token = await user.getIdToken();
      console.log("signed in");
      await registerUser(token);
      connectToSocket(token, user);
    }
  }

  function addHistoricalMessage(message) {
    historicalMessages.current.push(message);
    while (historicalMessages.current.length > 10) {
      historicalMessages.current.shift();
    }
  }

  function connectToSocket(token, user) {
    var reconnect = false;
    if (socket.current != null) {
      socket.current.disconnect();
      reconnect = true;
    }
    socket.current = io(API_URL, {
      auth: {
        token: "Bearer " + token,
      },
    });
    console.log("Connecting to socket", API_URL);

    socket.current.on("chat message", (message) => {
      let blockList = inMemoryBlockList;
      if (blockList.has(message.userId)) {
        return;
      }

      message.status = message.status || MESSAGE_STATUS_NEW;
      if (document.visibilityState !== "visible") {
        document.getElementById("favicon").href = "favicon-badged.ico";
        messageQueue.current.push(message);
        if (messageQueue.current.length >= 10) {
          messageQueue.current.shift();
        }
      } else {
        dispatch(createMessage(message));
      }
      addHistoricalMessage(message);
    });

    socket.current.on("connect_error", async (err) => {
      setConnected((prev) => {
        return Math.max(prev - 0.5, -100);
      });
      console.log("Socket connect error:", err.message);

      if (err.message === "user has not been registered") {
        console.log("re registering");
        await registerUser(token);
      }

      // You get disconnected for an old token, so refresh the token and reconnect
      const freshToken = await getAuth().currentUser.getIdToken();
      connectToSocket(freshToken, user);
    });

    socket.current.on("connect", () => {
      setConnected(1);
      console.log("Connected to socket");
      socket.current.emit("language", getLanguage());
    });

    socket.current.on("warning", (warning) => {
      //setBanWarning(warning);
      if (warning.type === "RATE_LIMITED") {
        console.log("rate limited", warning.time);
        setSendEnabled(false);
        setTimeout(() => {
          setSendEnabled(true);
        }, warning.time * 950); // Enable 5% sooner than the actual time
      }
    });

    socket.current.on("status", async (status) => {
      console.log("status", status);
      if (status === "NOT_VERIFIED") {
        setModalIsOpen(true);
      } else if (status === "LANG_RECEIVED") {
        if (reconnect === false) {
          socket.current.emit("status", "CONNECTED");
          const result = await fetch(
            API_URL + `messages?language=${getLanguage()}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          const historicalMessagesFromBackend = await result.json();
          historicalMessages.current = historicalMessagesFromBackend.messages;
          onClickReplay(false);
        }
      }
    });
  }

  function sendMessage(messageText, token) {
    let message = {
      id: uuidv4(),
      messageText: messageText,
      status: MESSAGE_STATUS_NEW,
      token: token,
    };

    socket.current.emit("chat message", message);
    const analytics = getAnalytics();
    logEvent(analytics, "sent_message", { messageLength: messageText.length });

    messagesSentCount.current++;
    if (messagesSentCount.current == 5) {
      toast.info(
        <CustomToastWithLink
          text="Have ideas for VentScape? Click here to leave a post on Reddit and let me know!"
          link="https://www.reddit.com/r/VentScape/submit"
        />,
        {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {},
        }
      );
    }

    if (messagesSentCount.current == 10) {
      toast.info(
        <CustomToastWithLink
          text="Keep VentScape running! Click here to open the donation page"
          link="https://www.buymeacoffee.com/ventscape"
        />,
        {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          onClose: () => {},
        }
      );
    }
  }

  async function phoneNumberReceived() {
    const token = await getAuth().currentUser.getIdToken();
    await registerUser(token);
    connectToSocket(token, getAuth().currentUser);
  }

  function getLanguage() {
    var language = navigator.language || navigator.userLanguage || "en";
    if (language.includes("en")) {
      language = "en";
    }
    return language;
  }

  function onClickReplay(realClick = true) {
    if (!replaying && !recycling) {
      setReplaying(true);
      displayQueueMessage([...historicalMessages.current], 2000);
      messageQueue.current = [];
    }

    if (realClick) {
      const analytics = getAnalytics();
      logEvent(analytics, "pressed_replay");
    }
  }

  async function onClickRecycle() {
    if (!replaying && !recycling) {
      setRecycling(true);
      const token = await getAuth().currentUser.getIdToken();
      const response = await fetch(
        API_URL + `messages/random?language=${getLanguage()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const randomMessages = await response.json();
      console.log(randomMessages.messages);
      displayQueueMessage([...randomMessages.messages], 1000);
      messageQueue.current = [];
      const analytics = getAnalytics();
      logEvent(analytics, "pressed_recycle");
    }
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LegcT8aAAAAAF0lGhv70wvPtH2l8fOGI_3tAZwI">
      <RoomContainer ref={roomRef}>
        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <MessagesContainer sendBlockedUserToServer={sendBlockedUserToServer} />
        <ButtonContainer
          recycling={recycling}
          replaying={replaying}
          onClickSettings={() => {
            const nextOpenState = !settingsMenuIsOpen;
            setSettingsMenuIsOpen((prevState) => !prevState);
            const analytics = getAnalytics();
            logEvent(analytics, "pressed_settings", {
              open: nextOpenState,
            });
          }}
          onClickRecycle={onClickRecycle}
          onClickReplay={onClickReplay}
          connected={connected}
        />
        <PhoneSignUpModal
          visible={modalIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
          onSuccess={phoneNumberReceived}
        ></PhoneSignUpModal>

        <ReachOutModal
          visible={reachOutModalOpen}
          onClose={() => {
            setReachOutModalOpen(false);
          }}
        />
        <OnboardingModal
          visible={onboardingModalOpen}
          onClose={() => {
            setOnboardingModalOpen(false);
          }}
        />
        <SettingsMenu
          visible={settingsMenuIsOpen}
          openInstructions={() => {
            setOnboardingModalOpen(true);
          }}
          closeSettingsMenu={() => {
            setSettingsMenuIsOpen(false);
          }}
        ></SettingsMenu>
        <InputBar sendMessage={sendMessage} enabled={sendEnabled} />
      </RoomContainer>
      {/* <HighlightedComponents />
      <DisplayRandomMessages /> */}
    </GoogleReCaptchaProvider>
  );
}

export default RoomV2;
