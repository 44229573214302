import React from 'react';
import styled from 'styled-components';
import OnlineCounter from './onlineCounter';
import { device } from '../constants/device-sizes';
import { Link } from 'react-router-dom';

const RoomListItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const RoomListItemContainer = styled.div`
  width: 100%;
  margin: 0.75rem 1rem;
  padding: 1rem 1rem;
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  border-radius: 1rem;
  background: var(--clr-prim);
  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.laptop} {
    width: 40%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.h3`
  margin: auto;
  width: 100%;
  text-align: ${(props) => (props.type !== 'instructions' ? 'left' : 'center')};
`;

const SubTitle = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  font-size: var(--fs-body);
  white-space: pre-wrap;
  color: var(--clr-tert);
  width: 100%;
  text-align: ${(props) => (props.type !== 'instructions' ? 'left' : 'center')};
`;

const Description = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  white-space: pre-wrap;
  font-weight: var(--fw-light);
  font-size: var(--fs-body);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  cursor: pointer;
  background-color: var(--clr-green);
  font-size: var(--fs-body-bold);
  color: #fff;
  border: none;
  padding: 0.5rem 3rem;
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  border-radius: 1rem;
  :focus {
    outline-width: 0px;
    box-shadow: 0 0 0 2pt #ccc;
  }
`;

function RoomListItem(props) {
  const {
    title,
    name,
    type,
    onlineUsers,
    subtitle,
    description,
    connectButton,
  } = props.room;

  function getOnlineCounter() {
    if (type !== 'instructions') {
      return <OnlineCounter count={onlineUsers} marginBottom={'0.2rem'} />;
    }
  }

  function getConnectButton() {
    if (type !== 'instructions') {
      return (
        <ButtonContainer>
          <Link to={'/old/' + name}>
            <Button>{connectButton ? connectButton : 'Connect'}</Button>
          </Link>
        </ButtonContainer>
      );
    }
  }

  return (
    <RoomListItemWrapper>
      <RoomListItemContainer>
        <Header>
          <Title type={type}>{title}</Title>
          {getOnlineCounter()}
        </Header>
        <SubTitle type={type}>
          <i>{subtitle}</i>
        </SubTitle>
        <Description>{description}</Description>
        {getConnectButton()}
      </RoomListItemContainer>
    </RoomListItemWrapper>
  );
}

export default RoomListItem;
