import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app';
var firebaseConfig = {
  apiKey: 'AIzaSyB0H4TlU6AL3E-rrvrbVEqA71IsIJHAFTc',
  authDomain: 'ventscape-9f566.firebaseapp.com',
  projectId: 'ventscape-9f566',
  storageBucket: 'ventscape-9f566.appspot.com',
  messagingSenderId: '50234146469',
  appId: '1:50234146469:web:2b8f68a7ae3ea78ae3974c',
  measurementId: 'G-RSMEGEMRZZ',
};

initializeApp(firebaseConfig);
const analytics = getAnalytics();
logEvent(analytics, 'app_started');
console.log('?');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
