import React from 'react';
import styled from 'styled-components';
import { Users } from '@styled-icons/fa-solid/Users';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--clr-green);
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
  background: var(--clr-prim);
  margin-bottom: ${(props) => props.marginBottom};
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
`;

const Icon = styled(Users)`
  margin: auto 0.2rem auto 0;
  width: var(--fs-h3);
  height: var(--fs-h3);
`;

const Count = styled.span`
  margin: 0;
  font-size: var(--fs-body);
`;

function OnlineCounter(props) {
  return (
    <Container marginBottom={props.marginBottom}>
      <Icon></Icon>
      <Count>{props.count}</Count>
    </Container>
  );
}

export default OnlineCounter;
