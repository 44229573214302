import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { isiOSDevice as isIOSDevice } from "../../../constants/deviceType";
import InputContainer from "./InputContainer";
import IOSInputContainer from "./IOSInputContainer";
import ReactTooltip from "react-tooltip";
import useOnboardingTooltip from "../../../hooks/useOnboardingTooltip";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

function InputBar(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const containerRef = useRef();
  const tooltipDisabled = useOnboardingTooltip(containerRef, 1);

  function sendMessage(messageText) {
    if (messageText.length <= 0) {
      return;
    }

    executeRecaptcha("submit")
      .then((token) => {
        props.sendMessage(messageText, token);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Container>
      <Container
        ref={containerRef}
        data-tip={
          tooltipDisabled ? null : "Type here to send an anonymous message"
        }
        data-tip-disable={tooltipDisabled}
      >
        {isIOSDevice() ? (
          <IOSInputContainer
            sendMessage={sendMessage}
            enabled={props.enabled}
          ></IOSInputContainer>
        ) : (
          <InputContainer
            sendMessage={sendMessage}
            enabled={props.enabled}
          ></InputContainer>
        )}
      </Container>
    </Container>
  );
}

export default InputBar;
