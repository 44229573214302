import regex from "emoji-regex";
import { isMobile } from "../constants/device-sizes";

import { getPositionForNewDiv } from "../utils/geometry";
import { ASSIGNED_TOOLTIP_TO_MESSAGE } from "./onboarding-actions";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_MESSAGES = "UPDATE_MESSAGES";
export const CACHE_MESSAGE = "CACHE_MESSAGE";
export const POP_MESSAGE = "POP_MESSAGE";

export const MESSAGE_STATUS_NEW = "NEW";
export const MESSAGE_STATUS_CACHED = "CACHED";
export const MESSAGE_STATUS_RANDOM = "RANDOM";

const emojiRegex = regex();

export const addMessage = (message) => {
  return { type: ADD_MESSAGE, payload: { message } };
};

export const deleteMessage = (id) => {
  return { type: DELETE_MESSAGE, payload: { id } };
};

export const updateMessage = (message) => {
  return {
    type: UPDATE_MESSAGE,
    payload: { message },
  };
};

export const updateMessages = (messages) => {
  return {
    type: UPDATE_MESSAGES,
    payload: { messages },
  };
};

export const cacheMessage = (message) => {
  return {
    type: CACHE_MESSAGE,
    payload: { message },
  };
};

export const popMessage = () => {
  return {
    type: POP_MESSAGE,
    payload: {},
  };
};

export const createMessage = (message) => {
  return (dispatch, getState) => {
    const messages = Object.values(getState().messages.byId);
    let messageCountMultiplier, messageLengthMultiplier;

    if (isMobile()) {
      messageCountMultiplier = 1.342 - messages.length * 0.043;
      messageLengthMultiplier =
        2.01 - 0.269 * Math.log(message.messageText.length);
    } else {
      messageCountMultiplier = 1.357 - messages.length * 0.0366;
      messageLengthMultiplier =
        1.9855 - 0.239 * Math.log(message.messageText.length);
    }

    const emojis = Array.from(
      message.messageText.matchAll(emojiRegex),
      (m) => m[0]
    );

    const singleEmojiMultiplier =
      emojis.length === 1 && message.messageText.length <= 2
        ? isMobile()
          ? 1.8
          : 1.3
        : 1;

    const adminSizeMultiplier = message.sizeMultiplier || 1;

    let BASE_FONT_SIZE =
      parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue("--fs-body")
      ) * parseFloat(getComputedStyle(document.documentElement).fontSize);

    if (isMobile()) {
      BASE_FONT_SIZE = Math.ceil(BASE_FONT_SIZE * 0.6);
    }

    var finalSize = Math.max(
      messageCountMultiplier *
        messageLengthMultiplier *
        singleEmojiMultiplier *
        adminSizeMultiplier *
        BASE_FONT_SIZE,
      BASE_FONT_SIZE * 0.5
    );

    if (!isFinite(finalSize)) {
      // This could happen if we somehow received a 0 length message, for example. Display the message with close to normal font size
      finalSize = BASE_FONT_SIZE * 0.6;
    }

    message.rect = new DOMRect(0, 0, 0, 0);
    message.fontSize = finalSize;
    message.triggerFadeOut = false;

    const onboarding = getState().onboarding;

    if (onboarding.needsMessage) {
      message.tooltipDisabled = false;
      message.onboardingIndex = onboarding.index;
      dispatch({ type: ASSIGNED_TOOLTIP_TO_MESSAGE });
    } else {
      message.tooltipDisabled = true;
    }

    dispatch(addMessage(message));
  };
};

export const moveMessage = (id, width, height) => {
  return (dispatch, getState) => {
    const messages = Object.values(getState().messages.byId);
    const ui = Object.values(getState().ui);
    const { containerRect } = getState();

    const message = messages.find((msg) => msg.id === id);
    const initialRect = new DOMRect(0, 0, width, height);

    let max = 0.35 - 0.2 * Math.random();
    const ratio = Math.max(max - 0.035 * messages.length, 0.02);
    const leftInset = containerRect.width * ratio + Math.random() * 0.05;
    const rightInset = containerRect.width * ratio + Math.random() * 0.05;
    const topInset = containerRect.height * ratio + Math.random() * 0.05;
    const bottomInset = containerRect.height * ratio + Math.random() * 0.05;

    const boundingRect = new DOMRect(
      containerRect.left + leftInset,
      containerRect.top + topInset,
      containerRect.width - (leftInset + rightInset),
      containerRect.height - (topInset + bottomInset)
    );

    const existingMessages = messages
      .map((a) => a.rect)
      .filter((rect) => rect.height !== 0 && rect.width !== 0);

    const existingRects = existingMessages.concat(...ui);
    try {
      existingRects.push(
        document
          .getElementsByClassName("grecaptcha-badge")[0]
          .getBoundingClientRect()
      );
    } catch (err) {
      console.error(err);
    }

    const [newX, newY] = getPositionForNewDiv(
      initialRect,
      existingRects,
      boundingRect
    );

    message.rect = new DOMRect(newX, newY, width, height);

    const messageCountModifier = 1.395 - 0.0556 * messages.length;
    const messageTypeModifier =
      message.status === MESSAGE_STATUS_CACHED ? 0.6 : 1;
    let fadeOutDuration = 10000 + (10000 * message.messageText.length) / 150;

    fadeOutDuration *= messageCountModifier;
    fadeOutDuration *= messageTypeModifier;
    fadeOutDuration = Math.max(fadeOutDuration, 2000);
    fadeOutDuration = Math.min(fadeOutDuration, 25000);

    message.fadeOutDuration = fadeOutDuration;

    dispatch(updateMessage(message));
  };
};

export const removeMessage = (id) => {
  return (dispatch, getState) => {
    const messages = Object.values(getState().messages.byId);
    const message = messages.find((msg) => msg.id === id);

    if (
      message != null &&
      message.status != null &&
      message.status === MESSAGE_STATUS_NEW
    ) {
      dispatch(cacheMessage(message));
    }

    dispatch(deleteMessage(id));
  };
};

export const removeMessagesFromUserId = (userId) => {
  return (dispatch, getState) => {
    const allMessages = Object.values(getState().messages.byId);
    const filteredMessages = allMessages.filter((msg) => msg.userId === userId);

    for (const message of filteredMessages) {
      dispatch(deleteMessage(message.id));
    }
  };
};

export const popCachedMessage = () => {
  return (dispatch, getState) => {
    const cachedMessages = getState().messages.cached;

    if (cachedMessages.length === 0) {
      return Promise.resolve(true);
    }

    const latestCached = cachedMessages.slice(-1)[0];

    const message = {
      messageText: latestCached.messageText,
      id: latestCached.id,
      status: MESSAGE_STATUS_CACHED,
      color: latestCached.color,
      sizeMultiplier: latestCached.sizeMultiplier,
    };

    dispatch(createMessage(message));
    dispatch(popMessage());
    return Promise.resolve(false);
  };
};

export const fadeOutRandomMessages = () => {
  return (dispatch, getState) => {
    const messages = Object.values(getState().messages.byId);
    const randomMessages = messages
      .filter((message) => message.status === MESSAGE_STATUS_RANDOM)
      .map((message) => {
        message.triggerFadeOut = true;
        message.fadeOutDuration = 2000;
        return message;
      });
    dispatch(updateMessages(randomMessages));
  };
};
