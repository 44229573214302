import './App.css';
import { createGlobalStyle } from 'styled-components';
import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import Room from './components/room';
import RoomsMenu from './components/roomsMenu';
import { API_URL } from './constants/string-constants';
import { device } from './constants/device-sizes';
import RoomV2 from './components/v2/RoomV2';
import messagesReducer from './store/messages-reducer';
import containerReducer from './store/container-reducer';
import uiReducer from './store/ui-reducer';
import { setDarkMode } from './utils/localstorage';
import onboardingReducer from './store/onboarding-reducer';

const rootReducer = combineReducers({
  messages: messagesReducer,
  containerRect: containerReducer,
  ui: uiReducer,
  onboarding: onboardingReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const GlobalStyles = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    :root {
      --ff-primary: 'Open Sans';
      --ff-secondary: 'Open Sans';
      --fw-light: 200;
      --fw-reg: 300;
      --fw-bold: 900;
      
      --clr-prim: #1e1e1e;
      --clr-sec: #e1e1e1;
      --clr-tert: #969696;
      --clr-bg: #121212;
      --clr-invert-bg: #ffffff;
      --clr-cached: #999999;
      
      --clr-green: #31a33d;
      --clr-red: #c72929;
      --clr-purple: #c073de;

      --clr-shadow: #000000;

      --fs-h1: 3.25rem;
      --fs-h2: 1.75rem;
      --fs-h3: 1.5rem;

      --fs-body-bold: 1.15rem;
      --fs-body: 1rem;
      --fs-body-small: .75rem;
      --main-padding: 1rem 1rem;
    }

    /* Large Screens */
    @media ${device.tablet} {
        :root {
            --fs-h1: 4.5rem;
            --fs-h2: 2.5rem;
            --fs-h3: 1.75rem;

            --fs-body-bold: 1.15rem;
            --fs-body: 1.125rem;
            --fs-body-small: 0.85rem;
            --msg-width: 25%;
            --main-padding: 1rem 4rem;
            --minimize-height: 7.9rem;
        }
    }

    /* super small screens */
    @media (max-width: 320px) {
        :root {
            --fs-h1: 3rem;
            --fs-h2: 1.25rem;
            --fs-h3: 1rem;

            --fs-body-bold: 1.1rem;
            --fs-body: 1rem;
            --fs-body-small: 0.75rem;

            --btn-spacing: 0.25rem;
        }
    }

    html {
        scroll-behavior: smooth;
        height: 100%;
    }

    body {
        width: 100%;
        height: 100%;
        background: var(--clr-bg);
        color: var(--clr-sec);
        font-family: var(--ff-primary);
        line-height: 1.6;
        transition: all 0.2s linear;
    }

    h1,
    h2,
    h3 {
        line-height: 1;
        margin: 0;
    }

    h1 {
        font-size: var(--fs-h1);
    }
    h2 {
        font-size: var(--fs-h2);
    }
    h3 {
        font-size: var(--fs-h3);
    }

    h1,
    h2,
    h3,
    p,
    i,
    div {
      // text select stuff
    }
  `;

function App() {
  const instructions = useRef({
    title: 'Welcome to VentScape!',
    type: 'instructions',
    subtitle: 'let it out into the void\nsee others doing the same in realtime',
    description:
      'This is a site where you can anonymously vent whatever’s on your mind and see others doing the same in realtime.\n\nChoose a room from the list below and get venting!',
  });

  const errorMessage = useRef({
    title: 'Error - Failed to load Rooms',
    type: 'instructions',
    subtitle:
      "Looks like you weren't able to load the list of rooms for some reason. Most likely due to a connection issue with your internet, but if not, please message me (the dev) on Discord",
  });

  const [rooms, setRooms] = useState(false);

  useEffect(() => {
    setRooms(true);
  }, []);

  return (
    <Provider store={store}>
      <ModalProvider>
        <GlobalStyles />
        <Router>
          <Switch>
            <Route exact path='/'>
              <RoomV2></RoomV2>
            </Route>
            <Route exact path='/chat'>
              <Redirect to='/' />
            </Route>
            <Route exact path='/chat2'>
              <Redirect to='/' />
            </Route>
            <Route exact path='/chat3'>
              <Redirect to='/' />
            </Route>
            <Route exact path='/chat4'>
              <Redirect to='/' />
            </Route>
            <Route exact path='/chat5'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </Router>
      </ModalProvider>
    </Provider>
  );
}

export default App;
