import React, { useRef } from "react";
import styled from "styled-components";
import { Cog } from "@styled-icons/fa-solid/Cog";
import { UndoAlt } from "@styled-icons/fa-solid/UndoAlt";
import { Recycle } from "@styled-icons/fa-solid/Recycle";
import { SignalWifiStatusbar4Bar as WifiOn } from "@styled-icons/material/SignalWifiStatusbar4Bar";
import { SignalWifiStatusbarConnectedNoInternet4 as WifiOff } from "@styled-icons/material/SignalWifiStatusbarConnectedNoInternet4";
import useComponentRect from "../../hooks/useComponentRect";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUI } from "../../store/ui-actions";
import ReactTooltip from "react-tooltip";
import useOnboardingTooltip from "../../hooks/useOnboardingTooltip";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0.75rem;
`;

const Container = styled.div`
  display: flex;
  position: absolute;
  right: 1rem;
  justify-content: space-between;
`;

const ReplayButton = styled(UndoAlt)`
  cursor: pointer;
  transition: all 0.2s linear;
  width: 1.8rem;
  height: 2rem;
  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  :hover {
    transform: scale(1.2);
  }
  animation: ${(props) =>
    props.replaying ? "spin 2s linear infinite" : "none"};

  transform: all 2s linear;
  outline: none;
  margin-left: 0.5rem;
`;

const RecycleButton = styled(Recycle)`
  cursor: pointer;
  transition: all 0.2s linear;
  width: 1.8rem;
  height: 2rem;
  @keyframes spinn {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  :hover {
    transform: scale(1.2);
  }
  animation: ${(props) =>
    props.recycling ? "spinn 1.5s linear infinite" : "none"};

  transform: all 2s linear;
  outline: none;
  margin-left: 0.5rem;
`;

const SettingsButton = styled(Cog)`
  cursor: pointer;
  transition: all 0.2s linear;
  width: 1.8rem;
  height: 2rem;
  :hover {
    transform: rotate(60deg);
  }
  margin-left: 0.5rem;
`;

const OnlineIndicatorContainer = styled.div`
  transition: all 0.2s linear;
  height: 2rem;
  width: 2rem;
  color: ${(props) => {
    if (props.connected >= 1) {
      return "var(--clr-green)";
    } else if (props.connected >= 0) {
      return "orange";
    } else {
      return "red";
    }
  }};
`;

function ButtonContainer(props) {
  const dispatch = useDispatch();

  const containerRef = useRef();
  const settingsRef = useRef();
  const tooltipDisabled = useOnboardingTooltip(settingsRef, 4);
  const componentRect = useComponentRect(containerRef);

  useEffect(() => {
    dispatch(updateUI("buttonContainer", componentRect));
  }, [dispatch, componentRect]);

  return (
    <Wrapper>
      <Container ref={containerRef}>
        <OnlineIndicatorContainer
          data-tip={
            props.connected >= 1
              ? "You are connected to VentScape!"
              : "Can't connect to VentScape"
          }
          data-place="bottom"
          connected={props.connected}
        >
          {props.connected >= 1 ? <WifiOn /> : <WifiOff />}
        </OnlineIndicatorContainer>
        {/* <RecycleButton
          data-tip={
            props.recycling === true
              ? 'VentScape is displaying 5 random messages from the past'
              : 'Click to display 5 random messages from the past'
          }
          data-place='bottom'
          recycling={props.recycling}
          onClick={props.onClickRecycle}
        /> */}
        <ReplayButton
          data-tip={
            props.replaying === true
              ? "VentScape is replaying the last 10 messages"
              : "Click to replay the last 10 messages"
          }
          data-place="bottom"
          data-tip-disable={tooltipDisabled}
          replaying={props.replaying}
          onClick={props.onClickReplay}
        />
        <SettingsButton
          ref={settingsRef}
          data-tip={
            tooltipDisabled ? null : "Click here to open the settings menu"
          }
          onClick={props.onClickSettings}
        />
        <ReactTooltip place="left" />
      </Container>
    </Wrapper>
  );
}

export default ButtonContainer;
