import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowCircleUp } from '@styled-icons/fa-solid';
import { device } from '../constants/device-sizes';
import useKeyPress from './../hooks/useKeyPress';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { v4 as uuidv4 } from 'uuid';
import { isiOSDevice } from './../constants/deviceType';
import { CreativeCommonsNoncommercialEu } from '@styled-icons/entypo';
import { MAX_MESSAGE_LENGTH } from '../constants/message-constants';

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InputContainer = styled.div`
  border-radius: 2rem 2rem 0 0;
  filter: drop-shadow(0px 0px 4px var(--clr-shadow));
  background-color: var(--clr-prim);
  padding: 1rem 1rem 1rem 1rem;
  // margin: 0 auto;
  width: 100%;
  display: flex;
  @media ${device.tablet} {
    width: 50%;
  }
`;

const InputTextBox = styled.input`
  margin: 0;
  width: 100%;
  filter: none;
  border-radius: 1rem;
  font-size: var(--fs-body);
  background-color: var(--clr-prim);
  color: var(--clr-sec);
  padding: 0.25em 0.75em;
  text-align: center;
  border: 1px solid var(--clr-shadow);
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  :focus {
    outline-width: 0px;
    box-shadow: 0 0 0 2pt #ccc;
  }
`;

const InputContaineriOS = styled(InputContainer)`
  -webkit-appearance: none;
  position: absolute;
  bottom: var(--adj-height, 0px);
  z-index: var(--adj-z, -1);
  transform: var(--adj-scale, scale(1));
  transition: var(--adj-animation, none);
  filter: none;
  box-shadow: 0px 0px 8px var(--clr-shadow);
  padding-bottom: 3rem;
`;

const SubmitButton = styled(ArrowCircleUp)`
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  margin: 0 0 0 1rem;
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  background-color: var(--clr-prim);
  color: var(--clr-sec);
  border: 1px solid var(--clr-shadow);
  padding: 0.25rem 0.25rem;
  font-size: var(--fs-h3);
  :focus {
    outline-width: 0px;
    box-shadow: 0 0 0 2pt #ccc;
  }
`;

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function RoomInputText(props) {
  const inputTextBox = useRef();
  const inputTextBoxiOS = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useKeyPress('Enter', () => {
    if (isiOSDevice()) {
      onSubmitiOS();
    } else {
      onSubmit();
    }
  });

  function onSubmit() {
    submit(inputTextBox.current.value.trim());
    inputTextBox.current.value = '';
  }

  function onSubmitiOS() {
    submit(inputTextBoxiOS.current.value.trim());
    inputTextBoxiOS.current.value = '';
    inputTextBoxiOS.current.blur();
  }

  function submit(messageText) {
    if (messageText.length <= 0) {
      return;
    }
    let message = {
      id: uuidv4(),
      messageText: messageText,
      clicks: 0,
      size: 1.15,
      fontWeight: 900,
    };
    executeRecaptcha('submit').then((token) => {
      message.token = token;
      props.sendMessage(message);
    });

    props.displayMessage(message);
  }

  useEffect(() => {
    if (isiOSDevice()) {
      let maxPageHeight = window.innerHeight;
      inputTextBox.current.tabIndex = -1;
      document.documentElement.style.setProperty(
        '--adj-height',
        `${maxPageHeight}px`
      );

      inputTextBox.current.addEventListener('focus', (e) => {
        inputTextBoxiOS.current.focus();
        setTimeout(() => {
          document.documentElement.style.setProperty('--adj-animation', 'none');
          document.documentElement.style.setProperty('--adj-height', `0px`);
          document.documentElement.style.setProperty('--adj-z', '100');
          setTimeout(() => {
            document.documentElement.style.setProperty(
              '--adj-animation',
              'all 0.2s cubic-bezier(.17,.59,.4,.77)'
            );
            document.documentElement.style.setProperty(
              '--adj-height',
              `${
                maxPageHeight -
                window.visualViewport.height -
                convertRemToPixels(2)
              }px`
            );
          }, 100);
        }, 5);
      });

      inputTextBoxiOS.current.addEventListener('blur', (e) => {
        document.documentElement.style.setProperty('--adj-z', '-1');
        document.documentElement.style.setProperty(
          '--adj-height',
          `${maxPageHeight}px`
        );
        document.documentElement.style.setProperty(
          '--adj-animation',
          'all 0.01s ease'
        );
        setTimeout(() => {
          inputTextBox.current.value = inputTextBoxiOS.current.value;
        }, 10);
      });

      document.addEventListener('scroll', (e) => {
        if (document.activeElement === inputTextBoxiOS.current) {
          inputTextBoxiOS.current.blur();
        }
      });

      document.addEventListener('resize', (e) => {
        if (document.activeElement === inputTextBoxiOS.current) {
          inputTextBoxiOS.current.blur();
        }
      });
    }
  }, []);

  function setiOSComponent() {
    if (isiOSDevice()) {
      return (
        <InputContaineriOS>
          <InputTextBox
            ref={inputTextBoxiOS}
            placeholder="What's on your mind?"
            maxLength={MAX_MESSAGE_LENGTH}
          />
          <SubmitButton onClick={onSubmitiOS} />
        </InputContaineriOS>
      );
    } else {
      return null;
    }
  }

  return (
    <InputWrapper>
      <InputContainer>
        <InputTextBox
          ref={inputTextBox}
          placeholder="What's on your mind?"
          maxLength={MAX_MESSAGE_LENGTH}
        />
        <SubmitButton onClick={onSubmit} />
      </InputContainer>
      {setiOSComponent()}
    </InputWrapper>
  );
}

export default RoomInputText;
