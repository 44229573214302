import styled from 'styled-components';
import { Times } from '@styled-icons/fa-solid/Times';
import { useRef } from 'react';
import {
  readDarkModePreference,
  toggleDarkMode,
} from './../utils/localstorage';

const Container = styled.div`
  max-height: ${(props) => (props.isOpen ? '1000px' : '0px')};
  overflow: hidden;
  transition: background-color 0.2s linear;
  transition: max-height 0.2s linear;
  z-index: 10;
  border-radius: 0 0 2rem 2rem;
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  background-color: var(--clr-prim);
  position: absolute;
  top: 0px;
  width: 100%;
  margin: 0 auto;
`;

const CloseButton = styled(Times)`
  cursor: pointer;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 0px;
  right: 0px;
  margin: 1rem 1rem;
  font-size: 2rem;
  transition: all 0.2s linear;
  color: var(--clr-sec);
  :hover {
    color: red;
  }
`;

const SettingsList = styled.ul`
  list-style-type: none;
  margin: 0 0;
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
`;

const SettingsItem = styled.li`
  width: 50%;
  margin: auto auto 2rem;
  padding: 1rem;
  background-color: var(--clr-prim);
  border-radius: 2rem;
  color: var(--clr-sec);
  filter: drop-shadow(0px 4px 4px var(--clr-shadow));
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    margin: -1rem;
    padding: 1rem;
  }
`;

const SettingsMenu = (props) => {
  const darkModeButton = useRef();
  function getDarkModePref() {
    return readDarkModePreference();
  }

  function menuToggleDarkMode() {
    let darkModeEnabled = toggleDarkMode();
    darkModeButton.current.innerText = darkModeEnabled
      ? 'Light Mode'
      : 'Dark Mode';
  }

  return (
    <Container isOpen={props.isOpen}>
      <CloseButton onClick={props.closeSettingsMenu} />
      <SettingsList>
        <SettingsItem onClick={menuToggleDarkMode} ref={darkModeButton}>
          {getDarkModePref() ? 'Light Mode' : 'Dark Mode'}
        </SettingsItem>
        <SettingsItem>
          <a
            href='https://www.reddit.com/r/VentScape/'
            target='_blank'
            rel='noreferrer noopener'>
            Leave feedback for VentScape on Reddit!
          </a>
        </SettingsItem>
        <SettingsItem>
          <a
            href='https://discord.gg/rM7g5Zusmr'
            target='_blank'
            rel='noreferrer noopener'>
            Join the VentScape Discord
          </a>
        </SettingsItem>
      </SettingsList>
    </Container>
  );
};

export default SettingsMenu;
