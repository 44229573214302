import { getAnalytics, logEvent } from "firebase/analytics";
import React from "react";
import "react-dropdown/style.css";
import styled from "styled-components";
import Modal from "styled-react-modal";
import { device } from "./../../constants/device-sizes";
import ModalButton from "./ModalButton";
import TitleText from "./TitleText";

const ModalSC = Modal.styled`
  width: 100%;
  padding: 1rem;
  @media ${device.tablet} {
    width: 50%;
    position: auto;
    top: auto;
  }
  top: 0px;
  position: absolute;
`;

const ModalContent = styled.div`
  padding: 16px;
  background-color: var(--clr-bg);
  width: 100%;
  border-radius: 12px;
  shadow-opacity: 0.18;
  shadow-radius: 4px;
  shadow-color: #000;
  shadow-offset: 0px 4px;
  elevation: 10;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    margin: -1rem;
    padding: 1rem;
  }
`;

const Description = styled.p`
  color: var(--clr-sec);
  font-size: 1rem;
  margin: 0;
  @media ${device.tablet} {
    font-size: 1.2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;

  align-items: center;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
  width: 100%;
`;

const ModalButtonSC = styled.div`
  cursor: pointer;
  flex-grow: 1;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ReachOutModal = (props) => {
  function onPressContinue() {
    const analytics = getAnalytics();
    logEvent(analytics, "reach_out_modal_closed");
    props.onClose();
  }

  return (
    <ModalSC isOpen={props.visible} onBackgroundClick={props.onClose}>
      <ModalContent>
        <TitleText>Hey there!</TitleText>

        <Description>
          This is Gabe, creator of VentScape. I'm reaching out to active users
          of the site and would love to learn more about what you like/dislike
          about the site and what features you'd like to see added in. If that
          sounds good, please message me on Discord or email me at
          gabe@ventscape.life. Thank you so much!
        </Description>
        <ButtonContainer>
          <ModalButtonSC
            as={ModalButton}
            onClick={() => {
              const analytics = getAnalytics();
              logEvent(analytics, "discord_clicked", { referrer: "reach_out" });
            }}
          >
            <a
              href="https://discord.gg/rM7g5Zusmr"
              target="_blank"
              rel="noreferrer noopener"
            >
              Join the VentScape Discord
            </a>
          </ModalButtonSC>
          <ModalButtonSC as={ModalButton} onClick={onPressContinue}>
            Back to VentScape
          </ModalButtonSC>
        </ButtonContainer>
      </ModalContent>
    </ModalSC>
  );
};

export default ReachOutModal;
