import React from 'react';
import RoomListItem from './roomListItem';
import Header from './header';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;
function RoomsMenu(props) {
  return (
    <React.Fragment>
      <Header />
      <Container>
        {props.rooms.map((room) => (
          <RoomListItem key={room.name + room.type} room={room} />
        ))}
      </Container>
    </React.Fragment>
  );
}

export default RoomsMenu;
