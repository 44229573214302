import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../../../constants/device-sizes';
import useKeyPress from '../../../hooks/useKeyPress';
import InputBox from './InputBox';
import SubmitButton from './SubmitButton';

const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone;

export const Container = styled.div`
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
  padding-bottom: ${isInStandaloneMode() ? '1.75rem' : '0.75rem'};
  width: 100%;
  display: flex;
  @media ${device.tablet} {
    width: 50%;
  }
  background-color: var(--clr-bg);
  justify-content: space-between;
`;

function InputContainer(props) {
  const [inputText, setInputText] = useState('');
  const inputBox = useRef();
  const enabled = useRef(true);

  useEffect(() => {
    enabled.current = props.enabled;
  }, [props.enabled]);

  function submitMessage() {
    if (enabled.current) {
      props.sendMessage(inputBox.current.value);
      setInputText('');
    }
  }

  useKeyPress('Enter', () => {
    submitMessage();
  });

  return (
    <Container>
      <InputBox
        ref={inputBox}
        value={inputText}
        onChange={(text) => {
          setInputText(text);
        }}
      />
      <SubmitButton onSubmit={submitMessage} enabled={props.enabled} />
    </Container>
  );
}

export default InputContainer;
