import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
  color: var(--clr-sec);
  font-size: var(--fs-h2);
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
`;

const TitleText = (props) => {
  return <Title>{props.children}</Title>;
};

export default TitleText;
