import { useSelector } from "react-redux";

import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { setOnboardingIndex } from "../utils/localstorage";

function useOnboardingTooltip(ref, index) {
  const [tooltipDisabled, setTooltipDisabled] = useState(true);
  const [hasShown, setHasShown] = useState(false);
  const onboardingIndex = useSelector((state) => state.onboarding.index);

  useEffect(() => {
    if (onboardingIndex === index) {
      // console.log(
      //   "INDEXES MATCH:",
      //   index,
      //   "HAS SHOWN:",
      //   hasShown,
      //   "REF:",
      //   ref.current
      // );
      setTimeout(() => {
        if (hasShown === false) {
          setTooltipDisabled(false);
          ReactTooltip.show(ref.current);
          setHasShown(true);
        }
      }, 1000);

      setTimeout(() => {
        ReactTooltip.hide(ref.current);
        setTooltipDisabled(true);
      }, 7500);
    } else {
      setTooltipDisabled(true);
    }
  }, [onboardingIndex]);

  return tooltipDisabled;
}

export default useOnboardingTooltip;
