import React, { useState, useEffect, useLayoutEffect } from 'react';

function useComponentRect(ref) {
  const [componentRect, setComponentRect] = useState(new DOMRect(0, 0, 0, 0));

  useEffect(() => {
    function handleResize() {
      if (!ref || !ref.current) {
        return null;
      }
      setComponentRect(ref.current.getBoundingClientRect());
    }

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
    } else {
      window.addEventListener('resize', handleResize);
    }

    handleResize();

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
      } else {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      setComponentRect(ref.current.getBoundingClientRect());
    }
  }, []);

  return componentRect;
}

export default useComponentRect;
