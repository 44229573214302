import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import RoomInputText from './roomInputText';
import { io } from 'socket.io-client';
import MessagesContainer from './messagesContainer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './room.css';
import { getMessagesCache, cacheMessage } from './../utils/localstorage';
import { API_URL } from './../constants/string-constants';
import RoomHeader from './roomHeader';
import SettingsMenu from './settingsMenu';
import { v4 as uuidv4 } from 'uuid';

const RoomContainer = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: var(--clr-bg);
  justify-content: space-between;
  flex-direction: column;
  transition: background-color 0.2s linear;
`;

function Room(props) {
  const [messages, setMessages] = useState(new Map());
  const [onlineUsers, setOnlineUsers] = useState();
  const [settingsMenuOpen, openSettingsMenu] = useState(false);
  const socket = useRef();
  const selfPostedIdsSet = useRef(new Set());

  useEffect(() => {
    if (props.room.type === 'solo') {
      // Immediately return and do not connect for solo rooms
      return;
    }
    socket.current = io(API_URL);
    socket.current.on('joinRoom', () => {
      console.log('Joining', props.room.name);
      socket.current.emit('joinRoom', props.room.name);
    });
    socket.current.on('chat message', (msg) => {
      if (!selfPostedIdsSet.current.has(msg.id)) {
        console.log('Received message:', msg.messageText);

        if (document.visibilityState !== 'visible') {
          // TODO: Maybe add %PUBLIC_URL%
          document.getElementById('favicon').href = 'favicon-badged.ico';
        }

        if (msg.id === undefined) {
          // Prevents crash if some message somehow doesn't have an ID
          msg.id = uuidv4();
        }
        if (msg.clicks === undefined) {
          msg.clicks = 0;
        }
        setMessages((prev) => {
          let result = new Map(prev);
          result.set(msg.id, msg);
          console.log(result);
          return result;
        });
      } else {
        selfPostedIdsSet.current.delete(msg.id);
      }
    });

    socket.current.on('notification', (message) => {
      alert(message);
    });

    socket.current.on('click event', (msg) => {
      console.log('received click event');
      setMessages((prev) => {
        // Don't add a click to a message we haven't yet received
        if (!prev.has(msg.id)) {
          return prev;
        }
        console.log('prev', prev);
        let result = new Map(prev);
        let newMessage = { ...result.get(msg.id) };
        newMessage.clicks++;
        result.set(newMessage.id, newMessage);
        return result;
      });
    });

    socket.current.on('update', function (update) {
      if (update.type === 'onlineUsers') {
        setOnlineUsers(update.onlineUsers);
      }
    });

    document.addEventListener('visibilitychange', function (e) {
      if (document.visibilityState === 'visible') {
        document.getElementById('favicon').href = 'favicon.ico';
      }
    });

    return function cleanup() {
      socket.current.disconnect();
    };
  }, []);

  function onRemove(id) {
    setMessages((prev) => {
      let result = new Map(prev);
      result.delete(id);
      return result;
    });
  }

  function displayMessage(message) {
    setMessages((prev) => {
      let result = new Map(prev);
      result.set(message.id, message);
      return result;
    });
  }

  function sendMessage(message) {
    if (props.room.type === 'solo') {
      return;
    }

    let sentMessages = getMessagesCache();
    if (sentMessages.has(message.messageText)) {
      // Duplicate message
      return;
    }
    cacheMessage(message.messageText);
    selfPostedIdsSet.current.add(message.id);
    socket.current.emit('chat message', message);
  }

  function setSettingsMenuStateOpen() {
    console.log('open');
    openSettingsMenu(true);
  }

  function setSettingsMenuStateClosed() {
    openSettingsMenu(false);
  }

  function onMessageClicked(msg) {
    socket.current.emit('click event', msg);
    setMessages((prev) => {
      let result = new Map(prev);
      let newMessage = { ...result.get(msg.id) };
      newMessage.clicks++;
      result.set(newMessage.id, newMessage);
      return result;
    });
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey='6LegcT8aAAAAAF0lGhv70wvPtH2l8fOGI_3tAZwI'>
      <RoomContainer>
        <RoomHeader
          onlineUsers={onlineUsers}
          isSolo={props.room.type === 'solo'}
          openSettingsMenu={setSettingsMenuStateOpen}
        />
        <SettingsMenu
          isOpen={settingsMenuOpen}
          closeSettingsMenu={setSettingsMenuStateClosed}
        />
        <MessagesContainer
          messages={messages}
          onRemove={onRemove}
          onMessageClicked={onMessageClicked}
        />
        <RoomInputText
          displayMessage={displayMessage}
          sendMessage={sendMessage}
        />
      </RoomContainer>
    </GoogleReCaptchaProvider>
  );
}

export default Room;
