/* eslint-disable import/no-anonymous-default-export */

import { UPDATE_UI } from './ui-actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_UI:
      return {
        ...state,
        [action.payload.id]: action.payload.uiRect,
      };
    default:
      return state;
  }
};
