import React from 'react';
import styled from 'styled-components';

const ContinueButton = styled.div`
  width: ${(props) => props.width};
  /* height: 48px; */
  border-radius: 32px;
  background-color: ${(props) => {
    if (props.isAffirmative) {
      return props.enabled ? 'var(--clr-purple)' : 'var(--clr-tert)';
    } else {
      return 'transparent';
    }
  }};
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 1rem;
  display: flex;
`;

const ButtonText = styled.p`
  font-size: 24px;
  font-weight: ${(props) => (props.isAffirmative ? 300 : 'bold')};
  color: ${(props) => (props.isAffirmative ? '#fff' : 'var(--clr-purple)')};
  text-align: center;
  text-align-vertical: center;
  margin: 0;
  padding: 0;
`;

const Indicator = styled.div`
  width: 48px;
  height: 48px;
  position: absolute;
`;

// Affirmative = affirmative button, a yes button, a continue button, etc
// !Affirmative = negative button, a no button, a cancel button, etc
const ModalButton = ({
  isAffirmative = true,
  enabled = true,
  theme,
  width = 'auto',
  children,
  onClick,
  isLoading = false,
}) => {
  function onPressInternal() {
    if (!isLoading) {
      onClick();
    }
  }
  return (
    <ContinueButton
      width={width}
      onClick={onPressInternal}
      isAffirmative={isAffirmative}
      enabled={enabled}>
      <ButtonText isAffirmative={isAffirmative}>{children}</ButtonText>
      {isLoading ? <Indicator /> : null}
    </ContinueButton>
  );
};

export default ModalButton;
