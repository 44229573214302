import styled from 'styled-components';
import OnlineCounter from './onlineCounter';
import { Cog } from '@styled-icons/fa-solid/Cog';
import { device } from './../constants/device-sizes';

const Container = styled.div`
  position: absolute;
  justify-content: flex-end;
  right: 0px;
  top: 0px;
  padding: 1rem 0.25rem;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 0.6rem 0.25rem;
  @media ${device.mobileS} {
    padding: 0.75rem 0.25rem;
  }
  @media ${device.tablet} {
    padding: 1rem 1rem;
  }
`;

const SettingsButton = styled(Cog)`
  cursor: pointer;
  transition: all 0.2s linear;
  margin-left: 0.25rem;
  width: 1.75rem;
  height: 2rem;
  :hover {
    transform: rotate(60deg);
  }
  @media ${device.tablet} {
    margin-left: 1rem;
  }
`;

const RoomButtonsContainer = (props) => {
  function getOnlineCounter() {
    if (!props.isSolo) {
      return <OnlineCounter count={props.onlineUsers} />;
    }
  }

  return (
    <Container>
      {getOnlineCounter()}
      <SettingsButton onClick={props.openSettingsMenu}></SettingsButton>
    </Container>
  );
};

export default RoomButtonsContainer;
